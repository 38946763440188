import dayjs from 'dayjs';
import { createSelector } from 'reselect';
import { NO_END } from '@ha/date';
import {
  areDatesInMaximumPeriod,
  areDatesInMinimumPeriod,
} from 'ha/models/BookingDates';
import { getListingDates } from './getListingDates';
import { getMinimalRentalPeriod } from './getMinimalRentalPeriod';
import { isAdvertiserOwner } from './advertiser';
import { getMaximumStay } from '../reducer';
import { getConversationLink } from './lastConversation';

function isAvailable(
  dates: ReturnType<typeof getListingDates>,
  minimalPeriod: ReturnType<typeof getMinimalRentalPeriod>,
  maximumStay: number,
  conversationURL: ReturnType<typeof getConversationLink>,
  isOwner: ReturnType<typeof isAdvertiserOwner>,
): boolean {
  const today = dayjs().startOf('day').toDate();
  const startDate = dayjs(dates.startDate).isAfter(today)
    ? dates.startDate
    : today;
  const isOverdue = dayjs(dates.endDate).isBefore(today);

  return (
    !isOwner &&
    !conversationURL &&
    !isOverdue &&
    areDatesInMinimumPeriod(
      {
        startDate,
        endDate: dates.endDate,
      },
      minimalPeriod,
    ) &&
    (dates.endDate.getTime() === new Date(NO_END).getTime() ||
      areDatesInMaximumPeriod(
        {
          startDate,
          endDate: dates.endDate,
        },
        maximumStay,
      ))
  );
}

const getIsAvailable = createSelector(
  [
    getListingDates,
    getMinimalRentalPeriod,
    getMaximumStay,
    getConversationLink,
    isAdvertiserOwner,
  ],
  isAvailable,
);

export { getIsAvailable, isAvailable };
