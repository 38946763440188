import React from 'react';

import { alpha } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import { ListingCancellationPolicy } from '@ha/api/v2/types/Listing';
import { track } from '@hbf/analytics';
import {
  PaperBadge as Badge,
  Grid,
  IconButton,
  units,
  Typography,
} from '@hbf/dsl/legacy';
import { Studapart } from '@hbf/icons/custom';
import {
  ArrowButtonLeft,
  ArrowButtonRight,
  PictureSun,
  RealEstateDimensionsBlock,
  VideoPlayerSlider,
} from '@hbf/icons/streamline-bold';

import { useConfig } from 'ha/helpers/config';
import { useIntl } from 'ha/i18n';
import NoPhotoImg from 'ha/img-src/static/nophotos.png';
import { FacilitiesValues } from 'ha/models/ListingFacilities/constants';
import { firstAvailableDay as getFirstAvailableDay } from 'ha/utils/exclusionDate/firstAvailableDay';

import { FavoriteButtonNext as FavoriteButton } from 'ha/components/FavoriteButton';
import { SlideRendererParams } from 'ha/components/Slider';
import { VirtualizeSlider } from 'ha/components/Slider/VirtualizeSlider';
import { useFavoriteListingActions } from 'ha/modules/FavoriteListing';
import { ImgixSize } from 'ha/modules/Image/constants';
import { Imgix } from 'ha/modules/Image/Imgix';
import { MarketTypeTag, useShowStudentAccomodation } from 'ha/modules/PBSA';
import { MarketTypeVariant } from 'ha/modules/PBSA/types';

import { ListingCardProps } from './types';

interface Props
  extends Pick<
    ListingCardProps,
    'cardLocation' | 'room' | 'isLazy' | 'cardPosition'
  > {
  withGallery?: boolean;
  withFavorites?: boolean;
}

const useStyles = makeStyles()(theme => ({
  media: {
    position: 'relative',
    width: '100%',
    paddingTop: '66.66%' /* 3:2 Aspect Ratio */,
  },
  moreMediaInfo: {
    marginTop: units.rem(1),
    color: theme.palette.common.white,
  },
  moreMediaText: {
    textDecoration: 'underline',
  },
  moreMediaIcon: {
    marginRight: units.rem(0.5),
    marginLeft: units.rem(1),
  },
  moreMediaIconFirst: {
    marginLeft: 0,
  },
  absolute: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  content: {
    padding: theme.spacing(1.5),
  },
  interactable: {
    zIndex: 2,
  },
  imageContainer: {
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
  },
  arrowButton: {
    color: theme.palette.common.white,
    filter: `drop-shadow(0px 1px 3px ${theme.palette.grey[700]})`,
    padding: units.rem(0.5),
  },
  arrowButtonDisabled: {
    color: alpha(theme.palette.common.white, 0.5),
  },
  favoriteButton: {
    marginLeft: 'auto',
  },
  imageCover: {
    objectFit: 'cover',
    height: '100%',
    width: '100%',
  },
  fullHeight: {
    height: '100%',
  },
  badge: {
    background: theme.palette.common.white,
    color: theme.palette.primary.main,
    borderRadius: units.rem(0.875),
    fontSize: units.rem(0.875),
    padding: units.yx(0.18, 0.55),
  },
  newBadge: {
    background: theme.palette.primary.main,
    color: theme.palette.white.main,
    fontWeight: theme.typography.fontWeightBold,
  },
  studapartBadge: {
    display: 'flex',
    position: 'relative',
    background: 'rgba(255,255,255,0.9)',
    borderRadius: 'initial',
    borderTopRightRadius: units.rem(0.875),
    top: theme.spacing(1.5),
    right: theme.spacing(1.5),
  },
  studapartIcon: {
    width: units.rem(6),
  },
  cancellationBadge: {
    color: theme.palette.grey['700'],
  },
  moreMediaOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: alpha(theme.palette.grey[700], 0.65),
  },
}));

const MaxPhotoCount = 5;

const PhotoGalleryWithControlsInternal: React.FC<Props> = ({
  room: {
    id,
    photos,
    exclusions,
    minimalRentalPeriod,
    photoCount,
    floorPlanCount,
    videoCount,
    street,
    city,
    isNew,
    cPolicy,
    advertiserId,
    isPartner,
    unitTypeId,
    isMultiUnit,
    studentHousing,
    coLiving,
  },
  room,
  isLazy,
  cardLocation = 'unspecified',
  withGallery,
  withFavorites,
  cardPosition,
}) => {
  const { T, formatDate } = useIntl();
  const { classes, cx } = useStyles();

  const { studapart } = useConfig();

  const showStudentAccomodation = useShowStudentAccomodation();

  const renderMarketTypeTag = () => {
    if (!showStudentAccomodation) return null;

    if (coLiving === FacilitiesValues.Yes) {
      return <MarketTypeTag withShadow variant={MarketTypeVariant.CO_LIVING} />;
    }

    if (studentHousing === FacilitiesValues.Yes) {
      return <MarketTypeTag withShadow />;
    }

    return null;
  };

  const {
    onClick: onFavoriteClick,
    isLoading: isFavoriteLoading,
    isFavorite,
  } = useFavoriteListingActions(unitTypeId);

  const [index, setIndex] = React.useState(0);

  const slideCount = Math.min(photos?.length || 0, MaxPhotoCount);

  const isFirstSlide = index === 0;
  const isLastSlide = index === slideCount - 1;

  const isCancellationFlexible =
    cPolicy === ListingCancellationPolicy.FLEXIBLE_30D;
  const showMoreMediaSlide =
    index === slideCount - 1 && photoCount > MaxPhotoCount;

  const handleFavoritesClick = React.useCallback(
    (e: React.MouseEvent) => {
      track('Favorite clicked', {
        type: 'button',
        unittypeid: unitTypeId,
        isMultiUnit,
        unitId: id,
        listingId: id,
        favoriteType: isFavorite ? 'unfavorite' : 'favorite',
        clickedOnPage: cardLocation,
      });

      onFavoriteClick(e);
    },
    [onFavoriteClick, id, isFavorite, unitTypeId, isMultiUnit, cardLocation],
  );

  const handlePrevIndex = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();

      if (!isFirstSlide) {
        setIndex(index - 1);
      }
    },
    [index, isFirstSlide],
  );

  const handleNextIndex = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();

      if (!isLastSlide) {
        setIndex(index + 1);
      }
    },
    [index, isLastSlide],
  );

  const handleOverlayClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();

      track('more photos available clicked', {
        cardLocation,
        position: cardPosition,
        unittypeid: room.unitTypeId,
        with_orpheus_score: room.rankWithOrpheus,
        with_orpheus_experiment_score: room.rankWithOrpheusExperiment,
      });
    },
    [cardLocation, room, cardPosition],
  );

  const handleChangeIndex = React.useCallback((newIndex: number) => {
    setIndex(newIndex);
  }, []);

  const listingDatesLabel = React.useMemo(() => {
    const firstAvailableDay = getFirstAvailableDay(
      exclusions,
      minimalRentalPeriod,
    );

    return firstAvailableDay
      ? T('Available from %(dates)s', {
          dates: formatDate('DD MMM YYYY', firstAvailableDay),
        })
      : false;
  }, [T, exclusions, minimalRentalPeriod, formatDate]);

  const hasGallery = Boolean(withGallery && photos?.length);

  // eslint-disable-next-line no-nested-ternary
  const listingPicture = hasGallery ? (
    <VirtualizeSlider
      overscanSlideAfter={0}
      overscanSlideBefore={0}
      slideCount={slideCount}
      style={{ height: '100%' }}
      containerStyle={{ height: '100%' }}
      index={index}
      onChangeIndex={handleChangeIndex}
      slideRenderer={({ index: currentIndex, key }: SlideRendererParams) => {
        const image = photos?.[currentIndex];

        if (!image) {
          return null;
        }

        return (
          <div className={classes.imageContainer} key={key}>
            <Imgix
              className={classes.imageCover}
              htmlAttributes={{
                role: 'presentation',
              }}
              isLazy={isLazy}
              src={image}
              width={ImgixSize.xxsmall.w}
              height={ImgixSize.xxsmall.h}
              imgixParams={{
                fit: 'crop',
                auto: 'format',
              }}
            />
            {currentIndex === slideCount - 1 && photoCount > MaxPhotoCount && (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                onClick={handleOverlayClick}
                className={classes.moreMediaOverlay}
              >
                <Typography.Paragraph
                  color="white"
                  className={classes.moreMediaText}
                >
                  {T('listing_card.last_slide_media')}
                </Typography.Paragraph>
                <Grid
                  container
                  className={classes.moreMediaInfo}
                  alignItems="center"
                  justifyContent="center"
                >
                  {photoCount > 0 && (
                    <React.Fragment>
                      <PictureSun
                        fontSize="inherit"
                        className={cx(
                          classes.moreMediaIcon,
                          classes.moreMediaIconFirst,
                        )}
                      />
                      {photoCount}
                    </React.Fragment>
                  )}
                  {floorPlanCount > 0 && (
                    <React.Fragment>
                      <RealEstateDimensionsBlock
                        fontSize="inherit"
                        className={classes.moreMediaIcon}
                      />
                      {floorPlanCount}
                    </React.Fragment>
                  )}
                  {videoCount > 0 && (
                    <React.Fragment>
                      <VideoPlayerSlider
                        fontSize="inherit"
                        className={classes.moreMediaIcon}
                      />
                      {videoCount}
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
            )}
          </div>
        );
      }}
    />
  ) : (
    <Imgix
      src={photos?.[0] || NoPhotoImg}
      htmlAttributes={{
        alt: `${listingDatesLabel} (${street}, ${city})`,
        itemProp: 'image',
        role: 'presentation',
        'data-test-locator': 'ListingCard Photo',
      }}
      isLazy={isLazy}
      width={ImgixSize.xxsmall.w}
      height={ImgixSize.xxsmall.h}
      className={classes.imageCover}
      imgixParams={{
        fit: 'crop',
        auto: 'format',
      }}
    />
  );

  return (
    <div className={classes.media}>
      <div
        className={cx(
          classes.absolute,
          classes.fullHeight,
          classes.interactable,
        )}
      >
        {listingPicture}
      </div>
      <Grid
        container
        alignContent="center"
        className={cx(classes.absolute, classes.content)}
      >
        {hasGallery && (
          <Grid item container justifyContent="space-between">
            <IconButton
              disableRipple={isFirstSlide}
              component="div"
              edge="start"
              aria-label={T('Previous image')}
              className={cx(
                classes.arrowButton,
                classes.interactable,
                isFirstSlide && classes.arrowButtonDisabled,
              )}
              onClick={handlePrevIndex}
              size="large"
            >
              <ArrowButtonLeft />
            </IconButton>
            <IconButton
              disableRipple={isLastSlide}
              component="div"
              edge="end"
              aria-label={T('Next image')}
              className={cx(
                classes.arrowButton,
                classes.interactable,
                isLastSlide && classes.arrowButtonDisabled,
              )}
              onClick={handleNextIndex}
              size="large"
            >
              <ArrowButtonRight />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Grid
        className={cx(classes.absolute, classes.content)}
        container
        direction="column"
        justifyContent="space-between"
      >
        <Grid item container justifyContent="space-between">
          {isCancellationFlexible && !showMoreMediaSlide && (
            <Grid item className={classes.interactable}>
              <Badge className={cx(classes.badge, classes.cancellationBadge)}>
                {T('search.cancellation_policy.tag.flexible_cancellation')}
              </Badge>
            </Grid>
          )}
          {isNew && !isCancellationFlexible && !showMoreMediaSlide && (
            <Grid item className={classes.interactable}>
              <Badge className={cx(classes.badge, classes.newBadge)}>
                {T('New')}
              </Badge>
            </Grid>
          )}
          {withFavorites && (
            <Grid
              item
              className={cx(classes.favoriteButton, classes.interactable)}
            >
              <FavoriteButton
                size="xs"
                onClick={handleFavoritesClick}
                isLoading={isFavoriteLoading}
                isFavorite={isFavorite}
              />
            </Grid>
          )}
        </Grid>
        {isPartner && advertiserId === studapart.advertiserId && (
          <Grid item container justifyContent="space-between">
            <Grid item className={classes.interactable}>
              <Badge className={cx(classes.badge, classes.studapartBadge)}>
                <Studapart className={classes.studapartIcon} />
              </Badge>
            </Grid>
          </Grid>
        )}
        {renderMarketTypeTag()}
      </Grid>
    </div>
  );
};

export const PhotoGalleryWithControls = React.memo(
  PhotoGalleryWithControlsInternal,
);
