import { createReducer } from 'ha/myredux/createReducer';

import { Actions } from '../constants';
import { ListingState } from '../types';

type State = ListingState['requiredDocuments'];

export const initialState: State = {
  isInitiated: false,
  isLoading: false,
  documents: [],
};

const requiredDocumentsReducer = createReducer(
  {
    [Actions.LOAD_REQUIRED_DOCUMENT_START]: state => ({
      ...state,
      isInitiated: true,
      isLoading: true,
    }),
    [Actions.LOAD_REQUIRED_DOCUMENT_DONE]: (state, payload) => ({
      ...state,
      isLoading: false,
      documents: payload,
    }),
    [Actions.LOAD_REQUIRED_DOCUMENT_FAIL]: state => ({
      ...state,
      isLoading: false,
    }),
  },
  initialState,
);

export { requiredDocumentsReducer };
