import { createSelector } from 'reselect';
import { getOriginalDescription } from '../reducer';

export const getHasDescriptionBase = (
  description: ReturnType<typeof getOriginalDescription>,
) => !!(description && typeof description === 'string');

export const getHasDescription = createSelector(
  [getOriginalDescription],
  getHasDescriptionBase,
);
