import { createReducer } from 'ha/myredux/createReducer';
import { Actions } from '../constants';
import { ListingState } from '../types';

type State = ListingState['strictRentalPeriod'];

export const initialState: State = { selectedRentalPeriodId: null };

const strictRentalPeriodReducer = createReducer(
  {
    [Actions.SET_SELECTED_STRICT_RENTAL_PERIOD_ID]: (state, payload) => ({
      ...state,
      selectedRentalPeriodId: payload.selectedRentalPeriodId,
    }),
  },
  initialState,
);
export { strictRentalPeriodReducer };
