import { createSelector } from 'reselect';
import { RootState } from '../types';
import { STATE_NAME } from '../constants';

const getRentalConditionsState = (state: RootState) =>
  state[STATE_NAME].rentalConditions;

export const getRentalConditions = createSelector(
  getRentalConditionsState,
  rentalConditionsState => rentalConditionsState.data || null,
);
