import { getListingMainPhoto } from 'ha/helpers/getListingMainPhoto';
import { createReducer } from 'ha/myredux/createReducer';

import { Actions } from '../constants';
import { ListingState } from '../types';

type State = ListingState['entity'];

export const initialState = {
  id: null,
  belongsToPartner: false,
  city: null,
  cityLocalized: null,
  costs: null,
  country: null,
  countryLocalized: null,
  images: [],
  kind: null,
  previewImage: null,
  searchURL: {
    name: null,
    path: null,
  },
  street: null,
  type: null,
  advertiser: {
    id: null,
    avatarURL: null,
    birthDate: null,
    completedBookingsCount: 0,
    countryCode: null,
    firstName: null,
    hasFacebookProfile: false,
    hasPhone: false,
    isEmailVerified: false,
    lastActivityAt: null,
    listingsCount: 0,
    registeredSince: null,
    spokenLanguages: [],
    study: null,
    work: null,
    isTrusted: false,
  },
  conversationLink: null,
  currency: null,
  currentOccupancy: 0,
  description: '',
  endDate: null,
  facilities: {},
  freePlaces: 0,
  haFee: 0,
  baseHaFee: 0,
  isDeleted: false,
  isHidden: false,
  state: 1,
  isInstantAllowed: false,
  latitude: null,
  longitude: null,
  minimumStayMonths: 0,
  partnerRoomId: null,
  listingPath: null,
  price: 0,
  startDate: null,
  videos: [],
  exclusions: [],
  bookablePeriods: [],
  maxBookableDays: 0,
};

const entityReducer = createReducer(
  {
    [Actions.LOAD_LISTING_DONE]: (state, payload) => ({
      ...state,
      ...payload.listing,
    }),
  },
  initialState,
);

export const getBaseHaFee = (state: State) => state.baseHaFee;
export const getCity = (state: State) => state.city;
export const getNeighborhood = (state: State) => state.neighborhood;
export const getCancellationPolicy = (state: State) => state.cancellationPolicy;
export const getCityLocalized = (state: State) => state.cityLocalized;
export const getCountry = (state: State) => state.country;
export const getCosts = (state: State) => state.costs;
export const getCountryCode = (state: State) => state.countryCode;
export const getCountryLocalized = (state: State) => state.countryLocalized;
export const getCurrency = (state: State) => state.currency;
export const getCurrentOccupancy = (state: State) => state.currentOccupancy;
export const getDescription = (state: State) => state.description;
export const getEndDate = (state: State) => state.lastDate;
export const getExclusions = (state: State) => state.exclusions || [];
export const getIsMultiUnit = (state: State) => state.isMultiUnit || false;
export const getUnitTypeUnits = (state: State) =>
  (state && 'units' in state && state.units) || [];
export const getFacilities = (state: State) => state.facilities;
export const getPropertyFacilities = (state: State) =>
  state.property?.facilities || {};
export const getFreePlaces = (state: State) => state.freePlaces;
export const getHaFee = (state: State) => state.haFee;
export const getIsBelongsToPartner = (state: State) => state.belongsToPartner;
export const getIsDeleted = (state: State) => state.isDeleted;
export const getIsHidden = (state: State) => state.isHidden;
export const getIsInstantAllowed = (state: State) => state.isInstantAllowed;
export const getKind = (state: State) => state.kind;
export const getPricingType = (state: State) => state.pricingType;
export const getPricingValues = (state: State) => state.pricingValues;
export const getLatitude = (state: State) => state.latitude;
export const getListingId = (state: State) => state.id;
export const getListingPath = (state: State) => state.listingPath;
export const getLongitude = (state: State) => state.longitude;
export const getMinPeriod = (state: State) => state.minimumStayMonths;
export const getPartnerRoomId = (state: State) => state.partnerRoomId;
export const getPartnerURL = (state: State) => state.partnerURL;
export const getPreferredTenant = (state: State) => ({
  couplesAllowed: (state.couplesAllowed || 'UNKNOWN').toUpperCase(),
  maxAge: state.maxAge,
  minAge: state.minAge,
  gender: state.preferredGender,
  status: state.facilities.tenant_status,
});
export const getPreviewImage = (state: State): string | null => {
  return getListingMainPhoto(state.photoURLList)?.url ?? null;
};
export const getPrice = (state: State) => state.price;
export const getSearchURL = (state: State) => state.searchURL;
export const getStartDate = (state: State) => state.firstDate;
export const getState = (state: State) => state.state;
export const getStreet = (state: State) => state.street;
export const getType = (state: State) => state.type;
export const getVideos = (state: State) => state.videoURLList;
export const getStaticGoogleMapURL = (state: State) =>
  state.staticGoogleMapURLs;

export { entityReducer };
