import * as hadate from '@ha/date';
import dayjs from 'dayjs';

const isInBookablePeriod = (bookablePeriod, date) => {
  const { from, to } = bookablePeriod;

  return hadate.isDayBetween(from, to, date);
};

const areDatesInBookablePeriods = (bookablePeriods, startDate, endDate) => {
  if (!startDate && !endDate) {
    return true;
  }

  if (!endDate) {
    return bookablePeriods.some(bookablePeriod =>
      isInBookablePeriod(bookablePeriod, dayjs(startDate).startOf('day')),
    );
  }

  return bookablePeriods.some(bookablePeriod => {
    const isStartDateInside = isInBookablePeriod(
      bookablePeriod,
      dayjs(startDate).startOf('day'),
    );
    const isEndDateInside = isInBookablePeriod(
      bookablePeriod,
      dayjs(endDate).startOf('day'),
    );

    return isStartDateInside && isEndDateInside;
  });
};
export { areDatesInBookablePeriods };
