import { DateTimeFormat, DateTimeFormatFunc, TranslateFunc } from '@ha/intl';
import { firstAvailableDay as getFirstAvailableDay } from 'ha/utils/exclusionDate/firstAvailableDay';
import { NormalizedAlgoliaListing } from 'ha/models/Listing/types';

export const getAvailableDateLabel = (
  T: TranslateFunc,
  formatDateTime: DateTimeFormatFunc,
  room: NormalizedAlgoliaListing,
) => {
  const { exclusions, minimalRentalPeriod } = room;
  const firstAvailableDate = getFirstAvailableDay(
    exclusions,
    minimalRentalPeriod,
  );

  if (!firstAvailableDate) return undefined;

  const formattedDate = formatDateTime(
    new Date(firstAvailableDate),
    DateTimeFormat.DATE_SHORT,
  );

  return T('date.from_%s', formattedDate);
};
