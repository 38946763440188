import { createSelector } from 'reselect';

import { getBookablePeriods } from '../reducer';

const getBookablePeriodsValues = createSelector(
  [getBookablePeriods],
  bookablePeriods => bookablePeriods ?? [],
);

export { getBookablePeriodsValues };
