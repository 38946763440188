import { createSelector } from 'reselect';
import { getIsAuthenticated } from 'ha/modules/AuthLogic/selectors';
import { isAdvertiserOwner } from './advertiser';

export const getShowReportBtnBase = (
  isAuthenticated: ReturnType<typeof getIsAuthenticated>,
  isOwner: ReturnType<typeof isAdvertiserOwner>,
): boolean => isAuthenticated && !isOwner;

export const getShowReportBtn = createSelector(
  [getIsAuthenticated, isAdvertiserOwner],
  getShowReportBtnBase,
);
