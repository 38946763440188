import { defineKey as T } from '@ha/intl';

export const DATES_NOT_AVAILABLE = T('Those dates are not available');
export const DATES_NOT_IN_MINIMUM_PERIOD = T(
  'dates.min_rental_period.%(minPeriod)s',
);
export const DATES_NOT_IN_MAXIMUM_PERIOD = T(
  'dates.max_rental_period.%(maxPeriod)s',
);
export const MOVE_IN_DATE_NOT_IN_MAXIMUM_BOOKABLE_DAYS = T(
  'listing.page_max_bookable_days_error',
);
