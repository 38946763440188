import { createSelector } from 'reselect';
import { areDatesInMinimumPeriod } from 'ha/models/BookingDates';
import { constants } from 'ha/constants/commonConstants';
import { getDesiredDates } from './getDesiredDates';
import { getMinimalRentalPeriod } from './getMinimalRentalPeriod';

const { listingFlexibleDays } = constants;

const areDesiredDatesInMinimumPeriod = createSelector(
  [getDesiredDates, getMinimalRentalPeriod],
  (desiredDates, minimalPeriod) =>
    areDatesInMinimumPeriod(
      {
        startDate: desiredDates.startDate,
        endDate: desiredDates.endDate,
      },
      minimalPeriod,
      listingFlexibleDays,
    ),
);

export { areDesiredDatesInMinimumPeriod };
