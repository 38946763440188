import * as hadate from '@ha/date';
import { hasBookableWindow } from './hasBookableWindow';

const dayInsideBookableWindow = ({ from, to }, day) =>
  hadate.isDayBetween(from, to, day);

const dayInsideSomeBookableWindow = (bookableWindows, day) =>
  bookableWindows.some(hasBookableWindow)
    ? bookableWindows.some(bookableWindow =>
        dayInsideBookableWindow(bookableWindow, day),
      )
    : true;

export { dayInsideSomeBookableWindow };
