import { FeaturedCities } from 'ha/constants/FeaturedCities';
import { createSelector } from 'reselect';

import { getCity } from '../reducer';

const getUrgencyMessageThreshold = createSelector(
  [getCity],
  city => FeaturedCities[city]?.urgencyMessageThreshold,
);

export { getUrgencyMessageThreshold };
