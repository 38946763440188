import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { RatingStarRibbon } from '@hbf/icons/streamline-bold';
import { GppGood } from '@hbf/icons/brand-regular';
import { Experiments } from 'ha/constants/experiments';
import { Experiment, Variant } from 'ha/modules/Experiments';
import { useIntl } from 'ha/i18n';
import { StarRating } from 'ha/ui/StarRating';
import { Typography, units, Grid } from '@hbf/dsl/legacy';

import { showAdvertiserReviewsSummary } from 'ha/helpers/showAdvertiserReviewsSummary';
import { useTrustedAdvertiserBadge } from 'ha/modules/Listing/useTrustedAdvertiserBadge';

const useStyles = makeStyles()(theme => ({
  advertiserInfo: {
    padding: units.yx(0.75, 1),
    borderTop: `1px solid ${theme.palette.grey[200]}`,

    '& > span': {
      display: 'inline',
      lineHeight: units.rem(1.3),
    },
  },
  byOwnership: {
    color: theme.palette.grey[400],
    marginRight: theme.spacing(0.4),
  },
  firstName: {
    color: theme.palette.grey[700],
  },
  badge: {
    color: theme.palette.primary.main,
    verticalAlign: 'middle',
    fontSize: units.rem(1),
    marginLeft: theme.spacing(0.4),
    marginBottom: theme.spacing(0.25),
  },
  checkShieldBadge: {
    color: '#1C5BF7',
    verticalAlign: 'middle',
    fontSize: units.rem(1),
    marginLeft: theme.spacing(0.4),
    marginBottom: theme.spacing(0.25),
  },
  ratingContainer: {
    width: 'fit-content',
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(0.2),
    paddingRight: theme.spacing(0.8),
    float: 'right',

    '& > span': {
      lineHeight: units.rem(1.1),
    },
  },
  ratingValue: {
    color: theme.palette.grey[700],
    fontSize: units.rem(0.875),
    fontWeight: 600,
  },
  totalRatingValue: {
    color: theme.palette.grey[400],
    fontSize: units.rem(0.75),
  },
  star: {
    marginRight: theme.spacing(0.2),
  },
}));

interface Props {
  firstName?: string;
  isExcellent?: boolean;
  moveInOverallRating?: number;
  moveInRatingsCount?: number;
  country?: string;
}

const AdvertiserSection: React.FC<Props> = ({
  firstName,
  isExcellent,
  moveInOverallRating,
  moveInRatingsCount,
  country,
}) => {
  const { T } = useIntl();
  const { classes } = useStyles();

  const { shouldShowTrustedAdvertiserBadge } = useTrustedAdvertiserBadge();

  const showMoveInReviewSummary =
    moveInOverallRating && moveInRatingsCount
      ? showAdvertiserReviewsSummary(
          moveInRatingsCount,
          moveInOverallRating,
          country,
        )
      : false;

  if (!firstName) {
    return null;
  }

  return (
    <Grid
      item
      className={classes.advertiserInfo}
      data-test-locator="Listing Card Advertiser Info"
    >
      {shouldShowTrustedAdvertiserBadge ? (
        <Experiment name={Experiments.LLX1472.name}>
          <Variant name="A">
            <Typography.Caption
              component="span"
              className={classes.byOwnership}
            >
              {T('label.by_ownership')}
            </Typography.Caption>
            <Typography.Caption
              component="span"
              fontWeight="bold"
              className={classes.firstName}
            >
              {firstName}
            </Typography.Caption>
            {isExcellent && (
              <RatingStarRibbon
                className={classes.badge}
                fontSize="small"
                data-test-locator="ExcellentAdvertiserBadge.RatingStarRibbon"
              />
            )}
          </Variant>
          <Variant name="B">
            {isExcellent ? (
              <React.Fragment>
                <Typography.Caption
                  component="span"
                  fontWeight="bold"
                  className={classes.firstName}
                >
                  {T('label.trusted_advertiser')}
                </Typography.Caption>
                <GppGood
                  className={classes.checkShieldBadge}
                  fontSize="small"
                  data-test-locator="ExcellentAdvertiserBadge.GppGood"
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Typography.Caption
                  component="span"
                  className={classes.byOwnership}
                >
                  {T('label.by_ownership')}
                </Typography.Caption>
                <Typography.Caption
                  component="span"
                  fontWeight="bold"
                  className={classes.firstName}
                >
                  {firstName}
                </Typography.Caption>
              </React.Fragment>
            )}
          </Variant>
        </Experiment>
      ) : (
        <React.Fragment>
          <Typography.Caption component="span" className={classes.byOwnership}>
            {T('label.by_ownership')}
          </Typography.Caption>
          <Typography.Caption
            component="span"
            fontWeight="bold"
            className={classes.firstName}
          >
            {firstName}
          </Typography.Caption>
          {isExcellent && (
            <RatingStarRibbon
              className={classes.badge}
              fontSize="small"
              data-test-locator="ExcellentAdvertiserBadge.RatingStarRibbon"
            />
          )}
        </React.Fragment>
      )}

      {!!moveInOverallRating && showMoveInReviewSummary && (
        <Grid
          container
          direction="row"
          alignItems="flex-end"
          className={classes.ratingContainer}
        >
          <StarRating
            max={1}
            readOnly
            value={1}
            size="small"
            className={classes.star}
            data-test-locator="Advertiser Review Rating"
          />
          <Typography.Caption component="span" className={classes.ratingValue}>
            {parseFloat(`${moveInOverallRating}`).toFixed(1)}
          </Typography.Caption>
          <Typography.Caption
            component="span"
            className={classes.totalRatingValue}
          >
            /5
          </Typography.Caption>
        </Grid>
      )}
    </Grid>
  );
};

export { AdvertiserSection };
