import { createSelector } from 'reselect';
import { ListingState } from '@ha/api/v2/types/Listing';
import { getState, getIsDeleted } from '../reducer';

const getIsUnlisted = createSelector(
  [getState, getIsDeleted],
  (state, isDeleted) => {
    return state !== ListingState.PUBLISHED || isDeleted;
  },
);

export { getIsUnlisted };
