import { useIntl } from 'ha/i18n';
import React from 'react';
import { isEnabled, useFeatureFlags } from '../FeatureFlags';

export const useTrustedAdvertiserBadge = () => {
  const { locale } = useIntl();
  const { trustedAdvertiserBadgeExperiment } = useFeatureFlags();
  const isEng = locale === 'en-gb' || locale === 'en-us';

  const shouldShowTrustedAdvertiserBadge = React.useMemo(() => {
    return isEng && isEnabled(trustedAdvertiserBadgeExperiment);
  }, [isEng, trustedAdvertiserBadgeExperiment]);
  return { shouldShowTrustedAdvertiserBadge };
};
