import { createSelector } from 'reselect';
import { areDatesAvailable } from 'ha/models/BookingDates';
import { getDesiredDates } from './getDesiredDates';
import { getListingDates } from './getListingDates';

const areDesiredDatesAvailable = createSelector(
  [getDesiredDates, getListingDates],
  (desiredDates, listingDates) =>
    areDatesAvailable(
      {
        startDate: desiredDates.startDate,
        endDate: desiredDates.endDate,
      },
      {
        startDate: listingDates.startDate,
        endDate: listingDates.endDate,
      },
    ),
);

export { areDesiredDatesAvailable };
