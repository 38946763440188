import { PropertyType } from '@ha/algolia';
import { TranslateFunc } from '@ha/intl';

export const getRoomLabel = (
  T: TranslateFunc,
  propertyType: PropertyType,
  bedroomCount?: string,
  currentOccupancy?: number,
  freePlaces?: number,
) => {
  if (!propertyType) {
    return '';
  }

  if (propertyType === PropertyType.studio) {
    if (!freePlaces) {
      return '';
    }

    return freePlaces === 1
      ? T('Space for 1 person')
      : T('Space for %s persons', freePlaces);
  }
  if (
    propertyType === PropertyType.privateRoom ||
    propertyType === PropertyType.sharedRoom
  ) {
    if (!currentOccupancy) {
      return '';
    }

    return `${currentOccupancy} ${
      currentOccupancy === 1 ? T('housemate') : T('housemates')
    }`;
  }
  if (!bedroomCount) {
    return '';
  }

  return bedroomCount === '1'
    ? `1 ${T('bedroom')}`
    : T('%s Bedrooms', bedroomCount);
};
