import { createReducer } from 'ha/myredux/createReducer';
import { Actions, LISTING_NOT_FOUND_ERROR_MESSAGE } from '../constants';
import { ListingState } from '../types';

type State = ListingState['loadState'];

export const initialState: State = {
  cityLocalised: '',
  countryLocalised: '',
  loading: false,
  hasError: false,
  isNotFound: false,
  initialized: false,
  isInstantBookable: false,
};

const loadStateReducer = createReducer(
  {
    [Actions.LOAD_LISTING_START]: state => ({
      ...state,
      loading: true,
    }),
    [Actions.LOAD_LISTING_DONE]: state => ({
      ...state,
      loading: false,
      hasError: false,
      isNotFound: false,
    }),
    [Actions.LOAD_CITY_DONE]: (state, { cityName, countryName }) => ({
      ...state,
      cityLocalised: cityName,
      countryLocalised: countryName,
    }),
    [Actions.LOAD_LISTING_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      hasError: true,
      isNotFound:
        payload && payload.message === LISTING_NOT_FOUND_ERROR_MESSAGE,
    }),
    [Actions.INITIALIZE]: state => ({
      ...state,
      initialized: true,
    }),
    [Actions.INITIALIZE_INSTANT_BOOKING]: (state, isInstantBookable) => ({
      ...state,
      isInstantBookable,
    }),
  },
  initialState,
);

export const getCountryLocalised = (state: State) => state.countryLocalised;
export const getHasError = (state: State) => state.hasError;
export const getIsNotFound = (state: State) => state.isNotFound;
export const getIsInitialized = (state: State) => state.initialized;
export const getIsInstantBookable = (state: State) => state.isInstantBookable;
export { loadStateReducer };
