import { dayInsideSomeBookableWindow } from 'ha/utils/bookableWindow';
import {
  areDatesAvailable,
  areDatesOutFromExclusionDates,
} from 'ha/models/BookingDates';
import { DesiredDates, ListingDates } from 'ha/models/BookingDates/types';
import { ListingExclusion } from '@ha/api/v2/types/Listing';

export interface BookableWindow {
  from: Date | string;
  to: Date | string;
}

const areDatesNotAvailable = (
  isUnlisted: boolean,
  desiredDates: DesiredDates,
  listingDates: ListingDates,
  bookableWindows: BookableWindow[],
  isOwner: boolean,
  exclusions: ListingExclusion[],
): boolean => {
  const { startDate, endDate } = desiredDates;
  const areDatesEmpty = !startDate && !endDate;

  return (
    !isUnlisted &&
    !areDatesEmpty &&
    (!areDatesAvailable(desiredDates, listingDates) ||
      !areDatesOutFromExclusionDates(desiredDates, exclusions) ||
      !dayInsideSomeBookableWindow(bookableWindows, startDate)) &&
    !isOwner
  );
};
export { areDatesNotAvailable };
