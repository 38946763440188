import { NO_END } from '@ha/date';
import { ListingDates } from 'ha/models/BookingDates/types';
import { createSelector } from 'reselect';
import { getStartDate, getEndDate } from '../reducer';

const getListingDates = createSelector(
  [getStartDate, getEndDate],
  (startDate, endDate): ListingDates => ({
    startDate: new Date(startDate),
    endDate: new Date(endDate || NO_END),
  }),
);

export { getListingDates };
