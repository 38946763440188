import React, { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { List, ListItem, Typography, units, Grid } from '@hbf/dsl/legacy';
import { useIntl } from 'ha/i18n';
import { sharpSansBold } from 'ha/constants/fonts/sourceSansHeaders';
import { UtilityBillsValues } from 'ha/models/Listing/types';
import { UtilityBillsLabelTranslations } from 'ha/models/ListingUtilityBills/translations';
import { PropertyType } from '@ha/algolia';
import { getAreaLabel } from 'ha/helpers/unitConverter';

import { getAvailableDateLabel } from 'ha/components/ListingCard/utils/getAvailableDateLabel';
import { getListingTypeTitle } from 'ha/modules/Listing/helpers';
import { getRoomLabel } from '../utils';
import { PricingSection } from './PricingSection';
import { AdvertiserSection } from './AdvertiserSection';
import { ListingCardProps } from './types';

interface Props {
  room: ListingCardProps['room'];
  isImperialSystem: boolean;
  hasStartDate?: boolean;
  showAdvertiserSection?: boolean;
  queryParams: ListingCardProps['queryParams'];
}

const useStyles = makeStyles()(theme => ({
  infoContainer: {
    padding: units.trbl(0.875, 1, 0.5, 1),
  },
  listItem: {
    paddingBottom: 0,
  },
  priceContainer: {
    padding: 0,
    alignItems: 'baseline',
    flexWrap: 'wrap',
  },
  priceLabel: {
    color: theme.palette.secondary.main,
    fontFamily: sharpSansBold.fontFamily,
    fontSize: units.rem(1.25),
  },
  fromLabel: {
    color: theme.palette.secondary.dark,
    fontFamily: sharpSansBold.fontFamily,
    fontSize: units.rem(0.75),
    textTransform: 'uppercase',
    marginRight: '4px',
  },
  billsContainer: {
    fontSize: units.rem(0.75),
    color: theme.palette.grey[600],

    [theme.breakpoints.down('md')]: {
      top: '3px',
    },
  },
  priceInfoDivider: {
    position: 'relative',
    top: '-1px',
    color: theme.palette.grey[500],
    fontWeight: theme.typography.fontWeightBold,
    marginRight: '1px',
    marginLeft: '1px',
    fontSize: units.rem(0.6),
  },
  infoDivider: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    color: theme.palette.grey[400],
  },
}));

const InfoInternal: React.FC<Props> = ({
  room,
  isImperialSystem,
  showAdvertiserSection = false,
  queryParams,
}) => {
  const { T, formatDateTime } = useIntl();
  const { classes } = useStyles();
  const {
    propertyType,
    bedroomCount,
    currentOccupancy,
    freePlaces,
    bedroomSize,
    price,
    currency,
    totalSize,
    advertiserMoveInReviewCount,
    advertiserMoveInOverallRating,
    advertiserFirstName,
    country,
    isExcellentAdvertiser,
  } = room;

  const convertedBedroomSize = useMemo(
    () => getAreaLabel(bedroomSize, isImperialSystem),
    [isImperialSystem, bedroomSize],
  );
  const convertedTotalSize = useMemo(
    () => getAreaLabel(totalSize, isImperialSystem),
    [isImperialSystem, totalSize],
  );

  const listingKindLabel = getListingTypeTitle(T, propertyType);

  const isPrivateOrSharedRoom =
    propertyType === PropertyType.privateRoom ||
    propertyType === PropertyType.sharedRoom;
  const isStudio = propertyType === PropertyType.studio;
  const isAptSizePresented =
    Boolean(!isPrivateOrSharedRoom && totalSize) ||
    Boolean(isPrivateOrSharedRoom && bedroomSize);

  const listingDatesLabel = getAvailableDateLabel(T, formatDateTime, room);

  const formattedPrice = Math.floor(price / 100).toString();

  const roomLabel = getRoomLabel(
    T,
    propertyType,
    bedroomCount,
    currentOccupancy,
    freePlaces,
  );

  return (
    <Grid container direction="column">
      <List className={classes.infoContainer}>
        <ListItem
          disableGutters
          className={classes.priceContainer}
          data-test-locator="Listing Card Price"
        >
          <meta itemProp="price" content={formattedPrice} />
          <meta itemProp="priceCurrency" content={currency} />
          <PricingSection room={room} queryParams={queryParams} />
          <Typography.Caption className={classes.billsContainer}>
            <span className={classes.priceInfoDivider}>/</span>
            {T('Month')} (
            {T(
              UtilityBillsLabelTranslations[
                room.utilities || UtilityBillsValues.EXCLUDED
              ],
            )}
            )
          </Typography.Caption>
        </ListItem>

        <ListItem disableGutters className={classes.listItem}>
          <Typography.Caption data-test-locator="Listing Card Main Info">
            {listingKindLabel}

            {roomLabel && !isPrivateOrSharedRoom && !isStudio && (
              <React.Fragment>
                <span className={classes.infoDivider}>•</span>
                {roomLabel}
              </React.Fragment>
            )}

            {isAptSizePresented && (
              <React.Fragment>
                <span className={classes.infoDivider}>•</span>
                {isPrivateOrSharedRoom
                  ? convertedBedroomSize
                  : convertedTotalSize}
              </React.Fragment>
            )}

            {roomLabel && (isPrivateOrSharedRoom || isStudio) && (
              <React.Fragment>
                <span className={classes.infoDivider}>•</span>
                {roomLabel}
              </React.Fragment>
            )}
          </Typography.Caption>
        </ListItem>

        <ListItem disableGutters className={classes.listItem}>
          <Typography.Caption data-test-locator="Listing Card Additional Info">
            {listingDatesLabel && listingDatesLabel}
          </Typography.Caption>
        </ListItem>
      </List>
      {showAdvertiserSection && (
        <AdvertiserSection
          firstName={advertiserFirstName}
          isExcellent={isExcellentAdvertiser}
          moveInOverallRating={advertiserMoveInOverallRating}
          moveInRatingsCount={advertiserMoveInReviewCount}
          country={country}
        />
      )}
    </Grid>
  );
};

const Info = React.memo(InfoInternal);
export { Info };
