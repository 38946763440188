import { createSelector } from 'reselect';

import { getListingEntity } from '../reducer';

const getPricingDiscountValues = createSelector(
  [getListingEntity],
  listing => listing.pricingDiscountValues?.[0],
);

export { getPricingDiscountValues };
