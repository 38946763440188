import { ListingKind } from '@ha/api/v2/types/Listing';

import { ScreenSize } from 'ha/modules/UI/constants';

import { ImgixSize } from 'ha/modules/Image/constants';

export const STATE_NAME = 'listing';

export const galleryOpenedEventName = 'Full screen gallery opened';

export const ACTION_PREFIX = 'LISTING_PAGE';

export const CLICKED_VIDEO_EVENT = {
  name: 'Clicked Video Thumbnail',
  getRequiredAttributes: (place: 'Thumbnail' | 'Gallery') => {
    return { place };
  },
};

export const Actions = {
  INITIALIZE: `${ACTION_PREFIX}/INITIALIZE`,
  SET_QUERY_PARAMS: `${ACTION_PREFIX}/SET_QUERY_PARAMS`,
  INITIALIZE_INSTANT_BOOKING: `${ACTION_PREFIX}/INITIALIZE_INSTANT_BOOKING`,

  LOAD_LISTING_START: `${ACTION_PREFIX}/LOAD_LISTING_START`,
  LOAD_LISTING_DONE: `${ACTION_PREFIX}/LOAD_LISTING_DONE`,
  LOAD_LISTING_FAIL: `${ACTION_PREFIX}/LOAD_LISTING_FAIL`,

  LOAD_ADVERTISER_DONE: `${ACTION_PREFIX}/LOAD_ADVERTISER_DONE`,

  LOAD_CITY_START: `${ACTION_PREFIX}/LOAD_CITY_START`,
  LOAD_CITY_DONE: `${ACTION_PREFIX}/LOAD_CITY_DONE`,

  DETECT_DESCRIPTION_LANGUAGE_START: `${ACTION_PREFIX}/DETECT_DESCRIPTION_LANGUAGE_START`,
  DETECT_DESCRIPTION_LANGUAGE_DONE: `${ACTION_PREFIX}/DETECT_DESCRIPTION_LANGUAGE_DONE`,
  DETECT_DESCRIPTION_LANGUAGE_FAIL: `${ACTION_PREFIX}/DETECT_DESCRIPTION_LANGUAGE_FAIL`,

  TRANSLATE_DESCRIPTION_START: `${ACTION_PREFIX}/TRANSLATE_DESCRIPTION_START`,
  TRANSLATE_DESCRIPTION_DONE: `${ACTION_PREFIX}/TRANSLATE_DESCRIPTION_DONE`,
  TRANSLATE_DESCRIPTION_FAIL: `${ACTION_PREFIX}/TRANSLATE_DESCRIPTION_FAIL`,

  TOGGLE_DESCRIPTION_LANGUAGE: `${ACTION_PREFIX}/TOGGLE_DESCRIPTION_LANGUAGE`,

  TOGGLE_FULLSCREEN_MAP: `${ACTION_PREFIX}/TOGGLE_FULLSCREEN_MAP`,

  LOAD_TENANT_REVIEWS_OVERALL_START: `${ACTION_PREFIX}/LOAD_TENANT_REVIEWS_OVERALL_START`,
  LOAD_TENANT_REVIEWS_OVERALL_DONE: `${ACTION_PREFIX}/LOAD_TENANT_REVIEWS_OVERALL_DONE`,
  LOAD_TENANT_REVIEWS_OVERALL_FAIL: `${ACTION_PREFIX}/LOAD_TENANT_REVIEWS_OVERALL_FAIL`,

  LOAD_REQUIRED_DOCUMENT_START: `${ACTION_PREFIX}/LOAD_REQUIRED_DOCUMENT_START`,
  LOAD_REQUIRED_DOCUMENT_DONE: `${ACTION_PREFIX}/LOAD_REQUIRED_DOCUMENT_DONE`,
  LOAD_REQUIRED_DOCUMENT_FAIL: `${ACTION_PREFIX}/LOAD_REQUIRED_DOCUMENT_FAIL`,

  LOAD_RENTAL_CONDITIONS_START: `${ACTION_PREFIX}/LOAD_RENTAL_CONDITIONS_START`,
  LOAD_RENTAL_CONDITIONS_DONE: `${ACTION_PREFIX}/LOAD_RENTAL_CONDITIONS_DONE`,
  LOAD_RENTAL_CONDITIONS_FAIL: `${ACTION_PREFIX}/LOAD_RENTAL_CONDITIONS_FAIL`,
  CLEAR_RENTAL_CONDITIONS: `${ACTION_PREFIX}/CLEAR_RENTAL_CONDITIONS`,

  LOAD_LAST_CONVERSATION_START: `${ACTION_PREFIX}/LOAD_LAST_CONVERSATION_START`,
  LOAD_LAST_CONVERSATION_DONE: `${ACTION_PREFIX}/LOAD_LAST_CONVERSATION_DONE`,
  LOAD_LAST_CONVERSATION_FAIL: `${ACTION_PREFIX}/LOAD_LAST_CONVERSATION_FAIL`,

  SET_SELECTED_STRICT_RENTAL_PERIOD_ID: `${ACTION_PREFIX}/SET_SELECTED_STRICT_RENTAL_PERIOD_ID`,

  SET_HAS_MOVE_IN_WINDOW_ERROR: `${ACTION_PREFIX}/SET_HAS_MOVE_IN_WINDOW_ERROR`,
};

export const LISTING_NOT_FOUND_ERROR_MESSAGE = 'Listing with that id not found';

export const INITIAL_MAP_ZOOM = 14;

export const GalleryImgixSizeByScreenSize = {
  [ScreenSize.XS]: ImgixSize.fullscreenMobileLarge,
  [ScreenSize.SM]: ImgixSize.fullscreenMobileLarge,
  [ScreenSize.MD]: ImgixSize.fullscreenDesktopSmall,
  [ScreenSize.LG]: ImgixSize.fullscreenDesktopMedium,
  [ScreenSize.XL]: ImgixSize.fullscreenDesktopLarge,
};

export const GridImgixSizeByScreenSize = {
  [ScreenSize.XS]: ImgixSize.xsmall,
  [ScreenSize.SM]: ImgixSize.small,
  [ScreenSize.MD]: ImgixSize.medium,
  [ScreenSize.LG]: ImgixSize.large,
  [ScreenSize.XL]: ImgixSize.large,
};

export const PageNameByKind: Record<ListingKind, string> = {
  [ListingKind.EntirePlace]: 'Entire place',
  [ListingKind.PrivateRoom]: 'Private room',
  [ListingKind.SharedRoom]: 'Shared room',
};

export const VIEW_ON_MAP_SECTION = 'viewOnMapSection';

export const STATIC_GOOGLE_MAP_CONFIG = {
  zoom: '15',
  format: 'jpg', // https://developers.google.com/maps/documentation/maps-static/start#ImageFormats
  scale: '2', // https://developers.google.com/maps/documentation/maps-static/start#scale_values
  maptype: 'roadmap',
  style: 'feature:poi.business|visibility:off',
} as const;

export const STATIC_GOOGLE_MAP_SIZE = {
  desktop: '800x320',
  mobile: '500x500',
};
