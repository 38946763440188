import { createSelector } from 'reselect';
import { getUserId } from 'ha/modules/AuthLogic/selectors';

import { RootState } from '../types';
import { STATE_NAME } from '../constants';

const getAdvertiserState = (state: RootState) => state[STATE_NAME].advertiser;

export const getAdvertiser = createSelector(
  getAdvertiserState,
  advertiserState => advertiserState.data,
);

export const getAdvertiserName = createSelector(
  [getAdvertiser],
  advertiser => advertiser?.firstName,
);

export const isAdvertiserOwner = createSelector(
  [getUserId, getAdvertiser],
  (currentUserId, advertiser) => currentUserId === advertiser?.id,
);

export const getAvatarUrl = createSelector(
  [getAdvertiser],
  advertiser => advertiser?.avatarURL,
);
