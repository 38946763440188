import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import { isNorthAmericanCountry } from '@ha/core/SupportedCountries';

import { STATE_NAME } from '../constants';
import { RootState } from '../types';

import { advertiserReducer as advertiser } from './advertiserReducer';
import { dateErrorsReducer } from './dateErrorsReducer';
import * as entitySelectors from './entityReducer';
import { entityReducer } from './entityReducer';
import * as fullscreenMapSelectors from './fullscreenMap';
import { fullscreenMap } from './fullscreenMap';
import { lastConversationReducer as lastConversation } from './lastConversationReducer';
import * as loadStateSelectors from './loadStateReducer';
import { queryParamsReducer as queryParams } from './queryParamsReducer';
import { rentalConditionsReducer as rentalConditions } from './rentalConditionsReducer';
import { requiredDocumentsReducer as requiredDocuments } from './requiredDocumentsReducer';
import { strictRentalPeriodReducer as strictRentalPeriod } from './strictRentalPeriodReducer';
import { tenantReviewsReducer as tenantReviews } from './tenantReviewsReducer';
import * as translationSelectors from './translationReducer';
import { translationReducer as translation } from './translationReducer';

const reducer = combineReducers({
  entity: entityReducer,
  loadState: loadStateSelectors.loadStateReducer,
  queryParams,
  translation,
  fullscreenMap,
  tenantReviews,
  dateErrors: dateErrorsReducer,
  strictRentalPeriod,
  requiredDocuments,
  lastConversation,
  advertiser,
  rentalConditions,
});

export const getRootState = (state: RootState) => state[STATE_NAME];

// entitySelectors
const getEntity = createSelector(getRootState, root => root?.entity);

export const getListingEntity = getEntity;

export const getCancellationPolicy = createSelector(
  getEntity,
  entitySelectors.getCancellationPolicy,
);
export const getCity = createSelector(getEntity, entitySelectors.getCity);

export const getNeighborhood = createSelector(
  getEntity,
  entitySelectors.getNeighborhood,
);

export const getCityLocalized = createSelector(
  getEntity,
  entitySelectors.getCityLocalized,
);
export const getCountry = createSelector(getEntity, entitySelectors.getCountry);
export const getCountryCode = createSelector(
  getEntity,
  entitySelectors.getCountryCode,
);
export const getCountryLocalized = createSelector(
  getEntity,
  entitySelectors.getCountryLocalized,
);
export const getCurrency = createSelector(
  getEntity,
  entitySelectors.getCurrency,
);
export const getCurrentOccupancy = createSelector(
  getEntity,
  entitySelectors.getCurrentOccupancy,
);
export const getOriginalDescription = createSelector(
  getEntity,
  entitySelectors.getDescription,
);
export const getEndDate = createSelector(getEntity, entitySelectors.getEndDate);
export const getFacilities = createSelector(
  getEntity,
  entitySelectors.getFacilities,
);
export const getPropertyFacilities = createSelector(
  getEntity,
  entitySelectors.getPropertyFacilities,
);
export const getFreePlaces = createSelector(
  getEntity,
  entitySelectors.getFreePlaces,
);
export const getHaFee = createSelector(getEntity, entitySelectors.getHaFee);
export const getBaseHaFee = createSelector(
  getEntity,
  entitySelectors.getBaseHaFee,
);
export const getIsDeleted = createSelector(
  getEntity,
  entitySelectors.getIsDeleted,
);
export const getIsHidden = createSelector(
  getEntity,
  entitySelectors.getIsHidden,
);
export const getIsInstantAllowed = createSelector(
  getEntity,
  entitySelectors.getIsInstantAllowed,
);
export const getKind = createSelector(getEntity, entitySelectors.getKind);

export const getPricingType = createSelector(
  getEntity,
  entitySelectors.getPricingType,
);

export const getPricingValues = createSelector(
  getEntity,
  entitySelectors.getPricingValues,
);

export const getLatitude = createSelector(
  getEntity,
  entitySelectors.getLatitude,
);
export const getListingId = createSelector(
  getEntity,
  entitySelectors.getListingId,
);
export const getLongitude = createSelector(
  getEntity,
  entitySelectors.getLongitude,
);
export const getMinPeriod = createSelector(
  getEntity,
  entitySelectors.getMinPeriod,
);
export const getMaximumStay = createSelector(
  getEntity,
  entity => entity.maximumStay,
);
export const getPartnerRoomId = createSelector(
  getEntity,
  entitySelectors.getPartnerRoomId,
);
export const getPreferredTenant = createSelector(
  getEntity,
  entitySelectors.getPreferredTenant,
);
export const getPrice = createSelector(getEntity, entitySelectors.getPrice);

export const getSearchURL = createSelector(
  getEntity,
  entitySelectors.getSearchURL,
);
export const getStartDate = createSelector(
  getEntity,
  entitySelectors.getStartDate,
);
export const getState = createSelector(getEntity, entitySelectors.getState);
export const getStreet = createSelector(getEntity, entitySelectors.getStreet);
export const getType = createSelector(getEntity, entitySelectors.getType);
export const getVideos = createSelector(getEntity, entitySelectors.getVideos);
export const getCosts = createSelector(getEntity, entitySelectors.getCosts);
export const getIsBelongsToPartner = createSelector(
  getEntity,
  entitySelectors.getIsBelongsToPartner,
);
export const getPreviewImage = createSelector(
  getEntity,
  entitySelectors.getPreviewImage,
);
export const getListingPath = createSelector(
  getEntity,
  entitySelectors.getListingPath,
);

export const getExclusions = createSelector(
  getEntity,
  entitySelectors.getExclusions,
);

export const getIsMultiUnit = createSelector(
  getEntity,
  entitySelectors.getIsMultiUnit,
);

export const getUnitTypeUnits = createSelector(
  getEntity,
  entitySelectors.getUnitTypeUnits,
);

export const getPartnerURL = createSelector(
  getEntity,
  entitySelectors.getPartnerURL,
);

export const getListingContractType = createSelector(
  getListingEntity,
  listingEntity => listingEntity?.contractType,
);

export const getIsImperialSystem = createSelector(
  getListingEntity,
  listingEntity => isNorthAmericanCountry(listingEntity?.countryCode),
);

// descriptionSelectors
const getTranslationState = createSelector(
  getRootState,
  root => root?.translation,
);

export const getTranslatedDescription = createSelector(
  getTranslationState,
  translationSelectors.getTranslatedDescription,
);
export const getIsTranslationLoading = createSelector(
  getTranslationState,
  translationSelectors.getIsTranslationLoading,
);
export const getIsTranslationShown = createSelector(
  getTranslationState,
  translationSelectors.getIsTranslationShown,
);
export const getDescriptionLanguage = createSelector(
  getTranslationState,
  translationSelectors.getDescriptionLanguage,
);

// loadStateSelectors
export const getLoadState = createSelector(
  getRootState,
  root => root?.loadState,
);

export const getCountryLocalised = createSelector(
  getLoadState,
  loadStateSelectors.getCountryLocalised,
);
export const getIsInitialized = createSelector(
  getLoadState,
  loadStateSelectors.getIsInitialized,
);
export const getHasError = createSelector(
  getLoadState,
  loadStateSelectors.getHasError,
);
export const getIsNotFound = createSelector(
  getLoadState,
  loadStateSelectors.getIsNotFound,
);

export const getIsInstantBookable = createSelector(
  getLoadState,
  loadStateSelectors.getIsInstantBookable,
);

// queryParamsSelectors
export const getQueryParams = createSelector(
  getRootState,
  root => root?.queryParams,
);

export const getBookablePeriods = createSelector(
  getEntity,
  state => state.bookablePeriods ?? [],
);

export const getTenantFeeRuleName = createSelector(
  getEntity,
  state => state.tenantFeeRuleName,
);

// fullscreenMapSelectors
const getFullscreenMapState = createSelector(
  getRootState,
  root => root?.fullscreenMap,
);

export const isFullscreenMapOpen = createSelector(
  getFullscreenMapState,
  fullscreenMapSelectors.isFullscreenMapOpen,
);

// dateErrorReducers

export const getDateErrorsState = createSelector(
  getRootState,
  root => root?.dateErrors,
);

export const getHasMoveInWindowError = createSelector(
  getDateErrorsState,
  dateErrors => dateErrors?.hasMoveInWindowError,
);

// strictRentalPeriodSelectors
export const getStrictRentalPeriodState = createSelector(
  getRootState,
  root => root?.strictRentalPeriod,
);
export const getSelectedStrictRentalPeriodId = createSelector(
  getStrictRentalPeriodState,
  strictRentalPeriodState => strictRentalPeriodState?.selectedRentalPeriodId,
);

export const getStaticGoogleMapURL = createSelector(
  getEntity,
  entitySelectors.getStaticGoogleMapURL,
);

export { reducer };
