import { createSelector } from 'reselect';
import { areDatesOutFromExclusionDates as areDatesOutFromExclusionDatesTheFirst } from 'ha/models/BookingDates';
import { getDesiredDates } from './getDesiredDates';
import { getExclusions } from '../reducer';

const areDatesOutFromExclusionDates = createSelector(
  [getDesiredDates, getExclusions],
  (desiredDates, exclusions) =>
    areDatesOutFromExclusionDatesTheFirst(
      {
        startDate: desiredDates.startDate,
        endDate: desiredDates.endDate,
      },
      exclusions,
    ),
);

export { areDatesOutFromExclusionDates };
