import React from 'react';

import { useIntl } from 'ha/i18n';

import { makeStyles } from 'tss-react/mui';
import { PaperBadge as Badge, Grid, units } from '@hbf/dsl/legacy';
import { Studapart } from '@hbf/icons/custom';
import { track } from '@hbf/analytics';

import { FavoriteButtonNext as FavoriteButton } from 'ha/components/FavoriteButton';

import { NormalizedAlgoliaListing } from 'ha/models/Listing/types';
import { useFavoriteListingActions } from 'ha/modules/FavoriteListing';

import { useConfig } from 'ha/helpers/config';
import { MarketTypeTag, useShowStudentAccomodation } from 'ha/modules/PBSA';
import { FacilitiesValues } from 'ha/models/ListingFacilities/constants';
import { ListingCardLocations } from 'ha/types/ListingCard';

const useStyles = makeStyles()(theme => ({
  absolute: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  badge: {
    background: theme.palette.common.white,
    color: theme.palette.primary.main,
    borderRadius: units.rem(0.875),
    fontSize: units.rem(0.875),
    padding: units.yx(0.18, 0.55),
  },
  cancellationBadge: {
    color: theme.palette.grey['700'],
  },
  content: {
    padding: theme.spacing(1.5),
  },
  favoriteButton: {
    marginLeft: 'auto',
  },
  interactable: {
    zIndex: 2,
  },
  studapartBadge: {
    display: 'flex',
    position: 'relative',
    background: 'rgba(255,255,255,0.9)',
    borderRadius: 'initial',
    borderTopRightRadius: units.rem(0.875),
    top: theme.spacing(1.5),
    right: theme.spacing(1.5),
  },
  studapartIcon: {
    width: units.rem(6),
  },
}));

interface Props {
  room: NormalizedAlgoliaListing;
  isCancellationFlexible: boolean;
  showMoreMediaSlide: boolean;
  withFavorites?: boolean;
  cardLocation?: ListingCardLocations;
}

const PhotoGalleryOverlayActions: React.FC<Props> = ({
  room: {
    id,
    isNew,
    advertiserId,
    isPartner,
    unitTypeId,
    isMultiUnit,
    studentHousing,
  },
  isCancellationFlexible,
  showMoreMediaSlide,
  withFavorites,
  cardLocation,
}) => {
  const { T } = useIntl();
  const { classes, cx } = useStyles();

  const { studapart } = useConfig();

  const showStudentAccomodation = useShowStudentAccomodation();

  const {
    onClick: onFavoriteClick,
    isLoading: isFavoriteLoading,
    isFavorite,
  } = useFavoriteListingActions(unitTypeId);

  const handleFavoritesClick = React.useCallback(
    (e: React.MouseEvent) => {
      track('Favorite clicked', {
        type: 'button',
        unittypeid: unitTypeId,
        isMultiUnit,
        unitId: id,
        listingId: id,
        favoriteType: isFavorite ? 'unfavorite' : 'favorite',
        clickedOnPage: cardLocation,
      });

      onFavoriteClick(e);
    },
    [unitTypeId, isMultiUnit, id, isFavorite, cardLocation, onFavoriteClick],
  );

  return (
    <Grid
      className={cx(classes.absolute, classes.content)}
      container
      direction="column"
      justifyContent="space-between"
    >
      <Grid item container justifyContent="space-between">
        {isCancellationFlexible && !showMoreMediaSlide && (
          <Grid item className={classes.interactable}>
            <Badge className={cx(classes.badge, classes.cancellationBadge)}>
              {T('search.cancellation_policy.tag.flexible_cancellation')}
            </Badge>
          </Grid>
        )}
        {isNew && !isCancellationFlexible && !showMoreMediaSlide && (
          <Grid item className={classes.interactable}>
            <Badge className={classes.badge}>{T('New')}</Badge>
          </Grid>
        )}
        {withFavorites && (
          <Grid
            item
            className={cx(classes.favoriteButton, classes.interactable)}
          >
            <FavoriteButton
              size="xs"
              onClick={handleFavoritesClick}
              isLoading={isFavoriteLoading}
              isFavorite={isFavorite}
            />
          </Grid>
        )}
      </Grid>
      {isPartner && advertiserId === studapart.advertiserId && (
        <Grid item container justifyContent="space-between">
          <Grid item className={classes.interactable}>
            <Badge className={cx(classes.badge, classes.studapartBadge)}>
              <Studapart className={classes.studapartIcon} />
            </Badge>
          </Grid>
        </Grid>
      )}
      {showStudentAccomodation && studentHousing === FacilitiesValues.Yes && (
        <MarketTypeTag withShadow />
      )}
    </Grid>
  );
};

export { PhotoGalleryOverlayActions };
