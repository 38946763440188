import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { ListingDates, DesiredDates } from './types';

dayjs.extend(isBetween);

export const areDatesAvailable = (
  desiredDates: DesiredDates,
  availableDates: ListingDates,
): boolean => {
  const availableEnd = dayjs(availableDates.endDate);
  const availableStart = dayjs(availableDates.startDate);

  if (!desiredDates.startDate) {
    return false;
  }

  const desiredStart = dayjs(desiredDates.startDate);

  if (desiredStart.isBefore(availableStart, 'day')) {
    return false;
  }

  if (desiredStart.isAfter(availableEnd, 'day')) {
    return false;
  }

  if (!desiredDates.endDate) {
    return true;
  }

  const desiredEnd = dayjs(desiredDates.endDate);

  if (desiredEnd.isBefore(desiredStart, 'day')) {
    return false;
  }

  if (!desiredStart.isBetween(availableStart, availableEnd, 'day', '[]')) {
    return false;
  }

  if (!desiredEnd.isBetween(availableStart, availableEnd, 'day', '[]')) {
    return false;
  }

  return true;
};
