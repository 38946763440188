import { createSelector } from 'reselect';
import {
  getIsTranslationShown,
  getTranslatedDescription,
  getOriginalDescription,
} from '../reducer';

export const getDescriptionBase = (
  isShown: ReturnType<typeof getIsTranslationShown>,
  translated: ReturnType<typeof getTranslatedDescription>,
  original: ReturnType<typeof getOriginalDescription>,
) => (isShown ? translated : original);

export const getDescription = createSelector(
  [getIsTranslationShown, getTranslatedDescription, getOriginalDescription],
  getDescriptionBase,
);
