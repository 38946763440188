import dayjs from 'dayjs';
import { createSelector } from 'reselect';
import { DesiredDates } from 'ha/models/BookingDates/types';
import { getQueryParams } from '../reducer';

const normalizeDate = (date: string | null) => {
  if (!date) {
    return null;
  }

  const normalizedDate = dayjs(date, 'YYYY-MM-DD');

  return normalizedDate.isValid() ? normalizedDate.toDate() : null;
};

const getDesiredDates = createSelector(
  [getQueryParams],
  (queryParams): DesiredDates => ({
    startDate: normalizeDate(queryParams.startDate),
    endDate: normalizeDate(queryParams.endDate),
  }),
);

export { getDesiredDates };
