import isBetween from 'dayjs/plugin/isBetween';

import { doRangesOverlap } from '@ha/date';
import dayjs from 'dayjs';
import { ListingExclusion } from '@ha/api/v2/types/Listing';
import { DesiredDates } from './types';

dayjs.extend(isBetween);

export const areDatesOutFromExclusionDates = (
  desiredDates: DesiredDates,
  exclusions: ListingExclusion[],
): boolean => {
  if (exclusions.length === 0) {
    return true;
  }

  if (!desiredDates.startDate && !desiredDates.endDate) {
    return true;
  }

  if (!desiredDates.endDate) {
    const startDate = dayjs(desiredDates.startDate);

    return !exclusions.some(exclusion =>
      startDate.isBetween(exclusion.from, exclusion.to),
    );
  }

  if (!desiredDates.startDate) {
    const endDate = dayjs(desiredDates.endDate);

    return !exclusions.some(exclusion =>
      endDate.isBetween(exclusion.from, exclusion.to),
    );
  }

  return !exclusions.some(exclusion =>
    doRangesOverlap(
      desiredDates.startDate!,
      desiredDates.endDate!,
      exclusion.from,
      exclusion.to,
    ),
  );
};
