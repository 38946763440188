import isEmpty from 'lodash-es/isEmpty';
import { createSelector } from 'reselect';
import { getTranslatedDescription } from '../reducer';

export const getHasTranslationBase = (
  translatedDescription: ReturnType<typeof getTranslatedDescription>,
) => !isEmpty(translatedDescription);

export const getHasTranslation = createSelector(
  [getTranslatedDescription],
  getHasTranslationBase,
);
