import { createSelector } from 'reselect';
import { getCoordinates } from './getCoordinates';

const getHasCoordinates = createSelector([getCoordinates], coordinates =>
  Object.values(coordinates).every(
    coord => coord !== null && coord !== undefined,
  ),
);

export { getHasCoordinates };
