import { createSelector } from 'reselect';

import { ListingKind } from '@ha/api/v2/types/Listing';
import { defineKey } from '@ha/intl';
import { ListingTypeTranslations } from 'ha/models/Listing/translations';

import { getKind, getType } from '../reducer';

const getListingTypeKey = createSelector([getType, getKind], (type, kind) => {
  return kind === ListingKind.EntirePlace
    ? ListingTypeTranslations[type]
    : defineKey('Room');
});

export { getListingTypeKey };
