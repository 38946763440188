import { createSelector } from 'reselect';
import { RootState } from '../types';
import { STATE_NAME } from '../constants';

const getLastConversationState = (state: RootState) =>
  state[STATE_NAME].lastConversation;

export const getConversationLink = createSelector(
  getLastConversationState,
  lastConversationState => {
    if (!lastConversationState.data) {
      return null;
    }

    // TODO replace with URL RESOLVER.
    return `/my/talk/${lastConversationState.data.id}`;
  },
);

export const getHasConversation = createSelector(
  [getConversationLink],
  path => !!path,
);
