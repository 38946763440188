import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { Rating, RatingProps } from '@hbf/dsl/legacy';

const useStyles = makeStyles()(theme => ({
  decimal: {
    color: theme.palette.warning.dark,
  },
  iconFilled: {
    color: theme.palette.warning.dark,
  },
  iconEmpty: {
    color: theme.palette.grey[300],
  },
}));

export const StarRating: React.FC<RatingProps> = props => {
  const { classes } = useStyles();

  return (
    <Rating
      classes={{
        iconEmpty: classes.iconEmpty,
        iconFilled: classes.iconFilled,
        decimal: classes.decimal,
      }}
      {...props}
    />
  );
};
