import { useFeatureFlags } from 'ha/modules/FeatureFlags';
import React from 'react';

const useShowStudentAccomodation = () => {
  const { showStudentResidenceTags } = useFeatureFlags();
  const showStudentAccomodation = React.useMemo(
    () => showStudentResidenceTags === 'on',
    [showStudentResidenceTags],
  );

  return showStudentAccomodation;
};

export { useShowStudentAccomodation };
