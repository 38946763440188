import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Typography, Grid, units } from '@hbf/dsl/legacy';
import { Graduate } from '@hbf/icons/custom';
import { Building1 } from '@hbf/icons/streamline-bold';

import { useIntl } from 'ha/i18n';

import { MarketTypeVariant } from '../../types';

const useStyles = makeStyles()(theme => ({
  tag: {
    padding: units.yx(0.25, 0.2),
    paddingLeft: units.rem(1),
    borderTopLeftRadius: units.pxToRem(4),
  },
  tagSolid: {
    color: theme.palette.common.white,
    background: 'rgba(45, 70, 88, 0.7)',
    maxWidth: 250,
    fontWeight: 'bold',
    '&:after': {
      transform: 'skew(-30deg, 0deg) translateX(-50%)',
    },
  },
  tagWithShadow: {
    color: theme.palette.common.white,
    position: 'absolute',
    fontWeight: 'bold',
    bottom: 0,
    height: units.rem(2),
    background:
      'linear-gradient(90deg, rgba(45, 70, 88, 0.7) 0%, rgba(45, 70, 88, 0.7) 49.48%, rgba(45, 70, 88, 0) 100%)',
    width: units.rem(15),
    marginLeft: units.rem(-1),
    zIndex: 2,
  },
  text: {
    color: theme.palette.common.white,
    flex: 1,
  },
  icon: {
    paddingRight: theme.spacing(1),
    fill: 'none',
  },
  coLivingIcon: {
    paddingRight: theme.spacing(1),
    height: theme.spacing(1.5),
  },
}));

interface Props {
  withShadow?: boolean;
  variant?: MarketTypeVariant;
}

const MarketTypeTag: React.FC<Props> = ({
  withShadow,
  variant = MarketTypeVariant.STUDENT_HOUSING,
}) => {
  const { classes } = useStyles();
  const { T } = useIntl();

  const isCoLivingVariant = variant === MarketTypeVariant.CO_LIVING;

  return (
    <Grid
      item
      container
      justifyContent="space-between"
      alignItems="center"
      direction="row"
      data-test-locator="StudentResidenceTag"
      className={`${classes.tag} ${
        withShadow ? classes.tagWithShadow : classes.tagSolid
      }`}
    >
      {isCoLivingVariant ? (
        <Building1 className={classes.coLivingIcon} />
      ) : (
        <Graduate className={classes.icon} />
      )}
      <Typography.Paragraph
        className={classes.text}
        data-test-locator="MarketTypeTag/Title"
      >
        {isCoLivingVariant
          ? T('label.coliving')
          : T('student_accommodation.search_page.student_residence_label')}
      </Typography.Paragraph>
    </Grid>
  );
};

export { MarketTypeTag };
