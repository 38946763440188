import dayjs from 'dayjs';

import { DateRange } from '@ha/date';
import { constants as COMMON_CONSTANTS } from 'ha/constants/commonConstants';
import { dayInsideSomeBookableWindow } from 'ha/utils/bookableWindow';
import {
  areDatesAvailable,
  areDatesInMinimumPeriod,
  areDatesInMaximumPeriod,
} from 'ha/models/BookingDates';
import { ListingDates, DesiredDates } from 'ha/models/BookingDates/types';
import { areDatesInBookablePeriods } from 'ha/utils/bookableWindow/areDatesInBookablePeriods';

import * as CONSTANTS from './constants';

const { listingFlexibleDays } = COMMON_CONSTANTS;

export const areDatesEmpty = ({ startDate, endDate }: DesiredDates) =>
  !(startDate || endDate);

export const isDateRangeValid = (
  isUnlisted: boolean,
  isOwner: boolean,
  desiredDates: DesiredDates,
  listingDates: ListingDates,
) =>
  !isUnlisted &&
  !isOwner &&
  !areDatesEmpty(desiredDates) &&
  !dayjs(desiredDates.startDate).isBefore(dayjs(), 'day') &&
  areDatesAvailable(
    {
      startDate: desiredDates.startDate,
      endDate: desiredDates.endDate,
    },
    {
      startDate: listingDates.startDate,
      endDate: listingDates.endDate,
    },
  );

interface Props {
  isUnlisted: boolean;
  isOwner: boolean;
  desiredDates: DesiredDates;
  listingDates: ListingDates;
  minimumRentalPeriod: number;
  maximumRentalPeriod: number;
  bookableWindows: DateRange[];
  bookablePeriods: DateRange[];
  maxBookableDays: number;
}

export const getListingErrorMessage = ({
  isUnlisted,
  isOwner,
  desiredDates,
  listingDates,
  minimumRentalPeriod,
  maximumRentalPeriod,
  bookableWindows,
  bookablePeriods,
  maxBookableDays,
}: Props): string => {
  if (
    maxBookableDays &&
    desiredDates.startDate &&
    !dayInsideSomeBookableWindow(bookableWindows, desiredDates.startDate)
  ) {
    return CONSTANTS.MOVE_IN_DATE_NOT_IN_MAXIMUM_BOOKABLE_DAYS;
  }

  if (
    desiredDates.endDate &&
    isDateRangeValid(isUnlisted, isOwner, desiredDates, listingDates)
  ) {
    if (
      !areDatesInMinimumPeriod(
        desiredDates,
        minimumRentalPeriod,
        listingFlexibleDays,
      )
    ) {
      return CONSTANTS.DATES_NOT_IN_MINIMUM_PERIOD;
    }
    if (
      !areDatesInMaximumPeriod(
        desiredDates,
        maximumRentalPeriod,
        listingFlexibleDays,
      )
    ) {
      return CONSTANTS.DATES_NOT_IN_MAXIMUM_PERIOD;
    }
  }

  if (
    !areDatesInBookablePeriods(
      bookablePeriods,
      desiredDates.startDate,
      desiredDates.endDate,
    )
  ) {
    return CONSTANTS.DATES_NOT_AVAILABLE;
  }

  return '';
};
