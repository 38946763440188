import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { DesiredDates } from './types';

dayjs.extend(isSameOrAfter);

export const areDatesInMaximumPeriod = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  desiredDates: DesiredDates = {
    startDate: null,
    endDate: null,
  },
  maximumMonths: number,
  flexDays = 1,
): boolean => {
  if (!desiredDates.startDate) {
    return false;
  }

  const desiredStart = dayjs(desiredDates.startDate);

  if (desiredStart.isBefore(dayjs(), 'day')) {
    return false;
  }

  if (maximumMonths <= 0) {
    return true;
  }

  if (!desiredDates.endDate) {
    return true;
  }

  const startDateWithFlexDays = desiredStart.clone().add(flexDays, 'day');
  const maximumStayDate =
    // some listings, for example charitable listings can have float numbers for maximumStay
    // 0.25 = 1 week, 0.5 = 2 weeks, 0.75 = 3 weeks
    maximumMonths >= 1
      ? startDateWithFlexDays.add(maximumMonths, 'month')
      : startDateWithFlexDays.add(maximumMonths * 4, 'week');

  return maximumStayDate.isSameOrAfter(desiredDates.endDate);
};
