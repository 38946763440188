import { createReducer } from 'ha/myredux/createReducer';

import { Actions } from '../constants';
import { ListingState } from '../types';

type State = ListingState['lastConversation'];

export const initialState: State = {
  isLoading: false,
};

const lastConversationReducer = createReducer(
  {
    [Actions.LOAD_LAST_CONVERSATION_START]: state => ({
      ...state,
      isLoading: true,
    }),
    [Actions.LOAD_LAST_CONVERSATION_DONE]: (state, payload) => ({
      ...state,
      isLoading: false,
      data: payload,
    }),
    [Actions.LOAD_LAST_CONVERSATION_FAIL]: state => ({
      ...state,
      isLoading: false,
    }),
  },
  initialState,
);

export { lastConversationReducer };
