import { Action } from '@housinganywhere/safe-redux';

export const DefaultCase = Symbol('DefaultCase');

export function createReducer<T = any>(
  declaration: { [K: string]: (state: T, payload: any) => T },
  initialValue: T,
): (state: T, action: Action) => T {
  // eslint-disable-next-line @typescript-eslint/default-param-last
  return function reducerFn(state = initialValue, action) {
    const reducer =
      declaration[action.type] || declaration[DefaultCase.toString()];

    // @ts-ignore
    return reducer ? reducer(state, action.payload, action) : state;
  };
}
