import { createReducer } from 'ha/myredux/createReducer';

import { Actions } from '../constants';
import { ListingState } from '../types';

type State = ListingState['advertiser'];

export const initialState: State = {
  // @ts-ignore
  data: {},
};

const advertiserReducer = createReducer(
  {
    [Actions.LOAD_ADVERTISER_DONE]: (state, payload) => ({
      ...state,
      data: payload,
    }),
  },
  initialState,
);

export { advertiserReducer };
