import { createReducer } from 'ha/myredux/createReducer';
import { Actions } from '../constants';
import { ListingState } from '../types';

type State = ListingState['fullscreenMap'];

export const initialState: State = {
  isFullscreenMapOpen: false,
};

const fullscreenMap = createReducer(
  {
    [Actions.TOGGLE_FULLSCREEN_MAP]: state => ({
      isFullscreenMapOpen: !state.isFullscreenMapOpen,
    }),
  },
  initialState,
);

export const isFullscreenMapOpen = (state: State) => state.isFullscreenMapOpen;
export { fullscreenMap };
