import { createSelector } from 'reselect';
import { dayInsideSomeBookableWindow } from 'ha/utils/bookableWindow';
import { getDesiredDates } from './getDesiredDates';
import { getBookableWindows } from './getBookableWindows';

const isStartDateInBookableWindow = createSelector(
  [getBookableWindows, getDesiredDates],
  (bookableWindows, desiredDates) =>
    dayInsideSomeBookableWindow(bookableWindows, desiredDates.startDate),
);

export { isStartDateInBookableWindow };
