import { createReducer } from 'ha/myredux/createReducer';

import { Actions } from '../constants';
import { ListingState } from '../types';

type State = ListingState['tenantReviews'];

export const initialState: State = {
  isInitiated: false,
  isLoading: false,
  overallRating: {
    moveInOverallRating: 0,
    overallRating: 0,
    moveOutOverallRating: 0,
    moveInReviewCount: 0,
    moveOutReviewCount: 0,
    reviewCount: 0,
    surveyTopics: {},
  },
};

const tenantReviewsReducer = createReducer(
  {
    [Actions.LOAD_TENANT_REVIEWS_OVERALL_START]: state => ({
      ...state,
      isLoading: false,
      isInitiated: true,
    }),
    [Actions.LOAD_TENANT_REVIEWS_OVERALL_DONE]: (state, payload) => ({
      ...state,
      isLoading: false,
      overallRating: payload.overallRating ?? initialState.overallRating,
    }),
    [Actions.LOAD_TENANT_REVIEWS_OVERALL_FAIL]: state => ({
      ...state,
      isLoading: false,
    }),
  },
  initialState,
);

export { tenantReviewsReducer };
