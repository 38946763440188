import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { LoveIt as LoveItLight } from '@hbf/icons/streamline-light';
import { LoveIt as LoveItBold } from '@hbf/icons/streamline-bold';
import { useIntl } from 'ha/i18n';

import { RoundIconButtonProps, RoundIconButton } from 'ha/ui/RoundIconButton';

interface Props extends RoundIconButtonProps {
  isLoading: boolean;
  isFavorite: boolean;
  iconVariant?: 'light' | 'bold';
}

const useStyles = makeStyles()(theme => ({
  icon: {
    color: theme.palette.grey[700],
    marginTop: '4px',
  },
}));

export const FavoriteButtonNext: React.FC<Omit<Props, 'children'>> = ({
  isLoading,
  isFavorite,
  iconVariant = 'bold',
  ...props
}) => {
  const { T } = useIntl();
  const { classes } = useStyles();

  return (
    <RoundIconButton
      aria-label={isFavorite ? T('Unfavorite') : T('Favorite')}
      title={isFavorite ? T('Unfavorite') : T('Favorite')}
      size="small"
      color="white"
      disabled={isLoading}
      {...props}
    >
      {iconVariant === 'light' && !isFavorite ? (
        <LoveItLight fontSize="inherit" className={classes.icon} />
      ) : (
        <LoveItBold
          color={isFavorite ? 'primary' : 'action'}
          fontSize="inherit"
        />
      )}
    </RoundIconButton>
  );
};
