import React from 'react';

import { alpha } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import { track } from '@hbf/analytics';
import { Grid, units, Typography } from '@hbf/dsl/legacy';
import {
  PictureSun,
  RealEstateDimensionsBlock,
  VideoPlayerSlider,
} from '@hbf/icons/streamline-bold';

import { useIntl } from 'ha/i18n';

import { ListingCardProps } from './types';

type Props = Pick<ListingCardProps, 'room' | 'cardPosition' | 'cardLocation'>;

const useStyles = makeStyles()(theme => ({
  moreMediaIcon: {
    marginRight: units.rem(0.5),
    marginLeft: units.rem(1),
  },
  moreMediaIconFirst: {
    marginLeft: 0,
  },
  moreMediaInfo: {
    marginTop: units.rem(1),
    color: theme.palette.common.white,
  },
  moreMediaOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: alpha(theme.palette.grey[700], 0.65),
    zIndex: 9,
  },
  moreMediaText: {
    textDecoration: 'underline',
  },
}));

const PhotoGalleryOverlay: React.FC<Props> = ({
  room,
  cardPosition,
  cardLocation,
}) => {
  const { T } = useIntl();
  const { classes, cx } = useStyles();

  const handleOverlayClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();

      track('more photos available clicked', {
        cardLocation,
        position: cardPosition,
        unittypeid: room.unitTypeId,
        with_orpheus_score: room.rankWithOrpheus,
        with_orpheus_experiment_score: room.rankWithOrpheusExperiment,
      });
    },
    [cardLocation, room, cardPosition],
  );

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      onClick={handleOverlayClick}
      className={classes.moreMediaOverlay}
    >
      <Typography.Paragraph color="white" className={classes.moreMediaText}>
        {T('listing_card.last_slide_media')}
      </Typography.Paragraph>
      <Grid
        container
        className={classes.moreMediaInfo}
        alignItems="center"
        justifyContent="center"
      >
        {room.photoCount > 0 && (
          <React.Fragment>
            <PictureSun
              fontSize="inherit"
              className={cx(classes.moreMediaIcon, classes.moreMediaIconFirst)}
            />
            {room.photoCount}
          </React.Fragment>
        )}
        {room.floorPlanCount > 0 && (
          <React.Fragment>
            <RealEstateDimensionsBlock
              fontSize="inherit"
              className={classes.moreMediaIcon}
            />
            {room.floorPlanCount}
          </React.Fragment>
        )}
        {room.videoCount > 0 && (
          <React.Fragment>
            <VideoPlayerSlider
              fontSize="inherit"
              className={classes.moreMediaIcon}
            />
            {room.videoCount}
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  );
};

export { PhotoGalleryOverlay };
