import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Card, Link } from '@hbf/dsl/legacy';
import { useRoomPageLink } from 'ha/modules/Nextjs';
import { buildParams } from '@ha/search-query';
import { useFeatureFlags } from 'ha/modules/FeatureFlags';
import { useIntl } from 'ha/i18n';

import { ListingCardLocations } from 'ha/types/ListingCard';
import { PhotoGalleryWithControls } from './PhotoGalleryWithControls';
import { PhotoGalleryWithControlsSwiper } from './PhotoGalleryWithControlsSwiper';
import { Info } from './Info';
import { ListingCardProps } from './types';

const useStyles = makeStyles()(theme => ({
  card: {
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
    },
    height: '100%',
    width: '100%',
  },
  cardLink: {
    textDecoration: 'none',
  },
}));

const ListingCard = React.forwardRef<HTMLDivElement, ListingCardProps>(
  (props, ref) => {
    const {
      onMouseEnter,
      onMouseLeave,
      room,
      withGallery,
      withFavorites,
      isLazy,
      onCardClick,
      queryParams,
      noFollow,
      linkTarget,
      cardLocation = ListingCardLocations.unspecified,
      isImperialSystem,
      showAdvertiserSection = false,
      cardPosition,
    } = props;
    const { classes } = useStyles();

    const { showNewGallery } = useFeatureFlags();
    const isNewGallery = showNewGallery === 'on';

    const { urlResolver } = useIntl();

    const redirectPath = React.useMemo(
      () => (room.unitTypePath ? room.unitTypePath : room.listingPath),
      [room],
    );
    const redirectLink = useRoomPageLink(redirectPath, queryParams || {});

    const handleMouseEnter = React.useCallback(() => {
      if (onMouseEnter) {
        onMouseEnter(room.id);
      }
    }, [onMouseEnter, room.id]);

    const handleMouseLeave = React.useCallback(() => {
      if (onMouseLeave) {
        onMouseLeave(room.id);
      }
    }, [onMouseLeave, room.id]);

    const cardElement = (
      <Card elevation={1} className={classes.card} ref={ref}>
        {isNewGallery ? (
          <PhotoGalleryWithControlsSwiper
            room={room}
            isLazy={isLazy}
            cardLocation={cardLocation}
            withGallery={withGallery}
            withFavorites={withFavorites}
            cardPosition={cardPosition}
          />
        ) : (
          <PhotoGalleryWithControls
            room={room}
            isLazy={isLazy}
            cardLocation={cardLocation}
            withGallery={withGallery}
            withFavorites={withFavorites}
            cardPosition={cardPosition}
          />
        )}

        <Info
          room={room}
          isImperialSystem={isImperialSystem}
          queryParams={queryParams}
          hasStartDate={Boolean(queryParams?.startDate)}
          showAdvertiserSection={showAdvertiserSection}
        />
      </Card>
    );

    return (
      <Link
        to={
          linkTarget === '_blank'
            ? redirectLink
            : `${urlResolver.formatHrefURL(redirectPath)}${buildParams(
                queryParams || {},
              )}`
        }
        target={linkTarget || '_self'}
        data-test-locator="Listing Card"
        rel={noFollow ? 'noopener noreferrer' : undefined}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={onCardClick}
        itemScope
        itemType="http://schema.org/Offer"
        className={classes.cardLink}
      >
        {cardElement}
      </Link>
    );
  },
);

export { ListingCard };
