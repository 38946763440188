import { createSelector } from 'reselect';
import * as hadate from '@ha/date';

import { getBookablePeriods } from '../reducer';
import { getMaxBookableDays } from './getMaxBookableDays';

// Bookable windows are calculated from bookablePeriods only for START_DATE
const getBookableWindows = createSelector(
  [getBookablePeriods, getMaxBookableDays],
  (bookablePeriods, maxBookableDays) =>
    bookablePeriods.map(({ from }) => ({
      from,
      to: hadate.addDays(from, maxBookableDays - 1),
    })),
);

export { getBookableWindows };
