import { createSelector } from 'reselect';
import { getExclusions } from '../reducer';
import { areDatesNotAvailable } from '../helpers/areDatesNotAvailable';

import { getListingDates } from './getListingDates';
import { getBookableWindows } from './getBookableWindows';
import { getDesiredDates } from './getDesiredDates';
import { getIsUnlisted } from './getIsUnlisted';
import { isAdvertiserOwner } from './advertiser';

const getShowNotAvailableDates = createSelector(
  [
    getIsUnlisted,
    getDesiredDates,
    getListingDates,
    getBookableWindows,
    isAdvertiserOwner,
    getExclusions,
  ],
  areDatesNotAvailable,
);

export { getShowNotAvailableDates };
