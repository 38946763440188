/* eslint-disable react/require-default-props */
import React from 'react';
import { useIntl } from 'ha/i18n';
import { makeStyles } from 'tss-react/mui';
import { Typography, units } from '@hbf/dsl/legacy';
import {
  ListingPricingType,
  ListingPricingValues,
  ListingRentalPeriodType,
} from '@ha/api/v2/types/Listing';
import { isEnabled, useFeatureFlags } from 'ha/modules/FeatureFlags';
import { getPrecisePriceRange } from 'ha/helpers/getPrecisePrices';

import { PricingSectionProps } from './types';
import { PricingSectionContext, PricingSectionProvider } from './context';

const useStyles = makeStyles()(theme => ({
  fromLabel: {
    color: theme.palette.secondary.dark,
    fontSize: units.rem(0.75),
    textTransform: 'uppercase',
    marginRight: theme.spacing(0.5),
  },
  priceLabel: {
    color: theme.palette.secondary.main,
    fontSize: units.rem(1.25),
  },
}));

const PriceLabel = ({
  price,
  showFromLabel = false,
  testId,
}: {
  price: number;
  showFromLabel?: boolean;
  testId?: string;
}) => {
  const { T } = useIntl();
  const { classes } = useStyles();
  const { formatPrice } = PricingSectionContext.useContext();
  return (
    <React.Fragment>
      {showFromLabel && (
        <span className={classes.fromLabel}>{T('from_price')}</span>
      )}
      <Typography.H3
        fontWeight="bold"
        className={classes.priceLabel}
        data-test-locator={`ListingCard.PriceLabel${
          testId ? `.${testId}` : ''
        }`}
      >
        {formatPrice(price)}
      </Typography.H3>
    </React.Fragment>
  );
};

const PricingSectionStrictRentalPricing = () => {
  const { room, queryParams } = PricingSectionContext.useContext();
  if (!room || room.minPrice === undefined) return null;

  if (
    room.minPrice === 0 ||
    room.minPrice === room.maxPrice ||
    queryParams?.startDate
  ) {
    return <PriceLabel price={room.price} testId="StrictRentalPricing" />;
  }

  return (
    <PriceLabel
      price={room.minPrice * 100}
      showFromLabel
      testId="StrictRentalPricing"
    />
  );
};

const PricingSectionPrecisePricing = () => {
  const { room, queryParams, parseDateFromQueryParams } =
    PricingSectionContext.useContext();
  if (!room || !room?.priceType || !room?.pricingValuesEUR?.precise) {
    return null;
  }

  const parsedDates = parseDateFromQueryParams(queryParams);

  const precisePriceRange = getPrecisePriceRange({
    pricingType: room?.priceType as ListingPricingType,
    pricingValues: room?.pricingValuesEUR as ListingPricingValues,
    dateRange: {
      from: parsedDates.startDate,
      to: parsedDates.endDate,
    },
  });

  if (precisePriceRange?.from) {
    return (
      <PriceLabel
        price={precisePriceRange.from * 100}
        showFromLabel={precisePriceRange.from !== precisePriceRange.to}
        testId="PrecisePricing"
      />
    );
  }

  return null;
};

const PricingSectionDynamicMinPricing = () => {
  const { room } = PricingSectionContext.useContext();
  if (!room || !room?.minPrice) return null;

  return (
    <PriceLabel
      price={room.minPrice * 100}
      showFromLabel
      testId="DynamicMinPricing"
    />
  );
};

export const PricingSection: React.FC<PricingSectionProps> = props => {
  const { withDynamicMinimumPrice, strictRentalPeriodSearch } =
    useFeatureFlags();

  const pricingComponent = React.useMemo(() => {
    // Strict Rental Period
    const isStrictRentalPeriod =
      isEnabled(strictRentalPeriodSearch) &&
      props.room.rentalPeriodType === ListingRentalPeriodType.STRICT;
    if (isStrictRentalPeriod && props.room.minPrice) {
      return <PricingSectionStrictRentalPricing />;
    }

    //  Precise Pricing (Discounted Pricing)
    const hasPrecisePrices =
      Object.keys(props.room?.pricingValuesEUR?.precise || {}).length > 0;
    if (hasPrecisePrices) return <PricingSectionPrecisePricing />;

    // Dynamic Minimum Pricing
    if (
      isEnabled(withDynamicMinimumPrice) &&
      props.room.priceType === 'monthly' &&
      props.room.minPrice
    ) {
      return <PricingSectionDynamicMinPricing />;
    }

    // Default Price
    return <PriceLabel price={props.room.price} />;
  }, [props.room, strictRentalPeriodSearch, withDynamicMinimumPrice]);

  return (
    <PricingSectionProvider {...props}>
      {pricingComponent}
    </PricingSectionProvider>
  );
};
