import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { DesiredDates } from './types';

dayjs.extend(isSameOrBefore);

export const areDatesInMinimumPeriod = (
  desiredDates: DesiredDates = {
    startDate: null,
    endDate: null,
  },
  minimumMonths = 0,
  flexDays = 1,
): boolean => {
  if (!desiredDates.startDate) {
    return false;
  }

  const desiredStart = dayjs(desiredDates.startDate);

  if (desiredStart.isBefore(dayjs(), 'day')) {
    return false;
  }

  if (minimumMonths <= 0) {
    return true;
  }

  if (!desiredDates.endDate) {
    return true;
  }

  return desiredStart
    .add(minimumMonths, 'month')
    .subtract(flexDays, 'day')
    .startOf('day')
    .isSameOrBefore(desiredDates.endDate);
};
