import { createReducer } from 'ha/myredux/createReducer';
import { Actions } from '../constants';
import { ListingState } from '../types';

type State = ListingState['queryParams'];

export const initialState: State = {
  startDate: null,
  endDate: null,
};

const queryParamsReducer = createReducer(
  {
    [Actions.SET_QUERY_PARAMS]: (_state, payload) => ({
      ...payload,
    }),
  },
  initialState,
);
export { queryParamsReducer };
