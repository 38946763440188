import { createSelector } from 'reselect';

import { getListingErrorMessage as getListingErrorMessageUtil } from 'ha/utils/availability';
import { getIsUnlisted } from './getIsUnlisted';
import { isAdvertiserOwner } from './advertiser';
import { getDesiredDates } from './getDesiredDates';
import { getListingDates } from './getListingDates';
import { getMinimalRentalPeriod } from './getMinimalRentalPeriod';
import { getBookableWindows } from './getBookableWindows';
import { getMaximumStay } from '../reducer';
import { getBookablePeriodsValues as getBookablePeriods } from './getBookablePeriods';
import { getMaxBookableDays } from './getMaxBookableDays';

const getListingErrorMessage = createSelector(
  [
    getIsUnlisted,
    isAdvertiserOwner,
    getDesiredDates,
    getListingDates,
    getMinimalRentalPeriod,
    getMaximumStay,
    getBookableWindows,
    getBookablePeriods,
    getMaxBookableDays,
  ],
  (
    isUnlisted,
    isOwner,
    desiredDates,
    listingDates,
    minimumRentalPeriod,
    maximumRentalPeriod,
    bookableWindows,
    bookablePeriods,
    maxBookableDays,
    // eslint-disable-next-line max-params
  ) => {
    return getListingErrorMessageUtil({
      isUnlisted,
      isOwner,
      desiredDates,
      listingDates,
      minimumRentalPeriod,
      maximumRentalPeriod,
      bookableWindows,
      bookablePeriods,
      maxBookableDays,
    });
  },
);

export { getListingErrorMessage };
