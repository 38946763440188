import { createSelector } from 'reselect';
import { RootState } from '../types';
import { STATE_NAME } from '../constants';

const getRequiredDocumentsState = (state: RootState) =>
  state[STATE_NAME].requiredDocuments;

export const getAreRequiredDocumentsReady = createSelector(
  getRequiredDocumentsState,
  requiredDocumentsState =>
    !requiredDocumentsState.isLoading && requiredDocumentsState.isInitiated,
);

export const getRequiredDocuments = createSelector(
  getRequiredDocumentsState,
  requiredDocumentsState => requiredDocumentsState.documents || [],
);
