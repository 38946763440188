import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { ListingCancellationPolicy } from '@ha/api/v2/types/Listing';
import { units, NoSSR } from '@hbf/dsl/legacy';

import { LoadableSwiperSlider } from 'ha/components/SwiperSlider';
import { ImgixSize } from 'ha/modules/Image/constants';
import { Imgix } from 'ha/modules/Image/Imgix';

import { PhotoGalleryOverlay } from './PhotoGalleryOverlay';
import { PhotoGalleryOverlayActions } from './PhotoGalleryOverlayActions';
import { ListingCardProps } from './types';

interface Props
  extends Pick<
    ListingCardProps,
    'cardPosition' | 'room' | 'cardLocation' | 'isLazy'
  > {
  withGallery?: boolean;
  withFavorites?: boolean;
}

const useStyles = makeStyles()(theme => ({
  absolute: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  imageContainer: {
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
    cursor: 'pointer',
  },
  imageContainerBorder: {
    borderRadius: 0,
    [theme.breakpoints.up('md')]: {
      borderRadius: units.rem(0.25),
    },
  },
  interactable: {
    zIndex: 2,
  },
  media: {
    position: 'relative',
    width: '100%',
    paddingTop: '66.66%' /* 3:2 Aspect Ratio */,
  },
  fullHeight: {
    height: '100%',
  },
  imageCover: {
    objectFit: 'cover',
    height: '100%',
    width: '100%',
  },
}));

const MaxPhotoCount = 5;

const PhotoGalleryWithControlsSwiper: React.FC<Props> = ({
  room: { photos, photoCount, cPolicy },
  room,
  isLazy,
  cardLocation,
  withFavorites,
  cardPosition,
}) => {
  const { classes, cx } = useStyles();

  const [index, setIndex] = React.useState(0);

  const slideCount = Math.min(photos?.length || 0, MaxPhotoCount);

  const slidePhotos = photos?.slice(0, slideCount);

  const handleChangeIndex = React.useCallback((newIndex: number) => {
    setIndex(newIndex);
  }, []);

  const isCancellationFlexible =
    cPolicy === ListingCancellationPolicy.FLEXIBLE_30D;
  const showMoreMediaSlide =
    index === slideCount - 1 && photoCount > MaxPhotoCount;

  const listingPhotos = React.useMemo(() => {
    const items = slidePhotos?.map(url => ({
      key: url,
      element: (
        <div
          aria-hidden="true"
          className={cx(classes.imageContainer, classes.imageContainerBorder)}
        >
          <Imgix
            className={classes.imageCover}
            htmlAttributes={{
              role: 'presentation',
            }}
            isLazy={isLazy}
            src={url}
            width={ImgixSize.xxsmall.w}
            height={ImgixSize.xxsmall.h}
            imgixParams={{
              fit: 'crop',
              auto: 'format',
            }}
          />
        </div>
      ),
    }));

    return (
      <LoadableSwiperSlider
        setIndexChange={handleChangeIndex}
        items={items || []}
        withDefaultControls
      >
        {index === slideCount - 1 && photoCount > MaxPhotoCount && (
          <PhotoGalleryOverlay
            room={room}
            cardLocation={cardLocation}
            cardPosition={cardPosition}
          />
        )}
      </LoadableSwiperSlider>
    );
  }, [
    slidePhotos,
    handleChangeIndex,
    index,
    slideCount,
    photoCount,
    room,
    cardLocation,
    cx,
    classes.imageContainer,
    classes.imageContainerBorder,
    classes.imageCover,
    isLazy,
    cardPosition,
  ]);

  return (
    <div className={classes.media}>
      <div
        className={cx(
          classes.absolute,
          classes.fullHeight,
          classes.interactable,
        )}
      >
        <NoSSR>{listingPhotos}</NoSSR>
      </div>
      <PhotoGalleryOverlayActions
        room={room}
        isCancellationFlexible={isCancellationFlexible}
        showMoreMediaSlide={showMoreMediaSlide}
        withFavorites={withFavorites}
        cardLocation={cardLocation}
      />
    </div>
  );
};

export { PhotoGalleryWithControlsSwiper };
