import { createSelector } from 'reselect';
import { RootState } from '../types';
import { STATE_NAME } from '../constants';

const getTenantReviewsState = (state: RootState) =>
  state[STATE_NAME].tenantReviews;

export const getTenantReviewsOverallRating = createSelector(
  getTenantReviewsState,
  tenantReviewsState => tenantReviewsState?.overallRating,
);

export const getAreTenantReviewsReady = createSelector(
  getTenantReviewsState,
  tenantReviewsState =>
    !tenantReviewsState.isLoading && tenantReviewsState.isInitiated,
);
