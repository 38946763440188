import { createReducer } from 'ha/myredux/createReducer';

import { Actions } from '../constants';
import { ListingState } from '../types';

type State = ListingState['dateErrors'];

export const initialState: State = {
  hasMoveInWindowError: false,
};

const dateErrorsReducer = createReducer(
  {
    [Actions.SET_HAS_MOVE_IN_WINDOW_ERROR]: (state, payload) => ({
      ...state,
      hasMoveInWindowError:
        payload.hasMoveInWindowError ?? initialState.hasMoveInWindowError,
    }),
  },
  initialState,
);

export { dateErrorsReducer };
