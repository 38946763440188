import { Grid, Typography, units, Link } from '@hbf/dsl/legacy';
import { makeStyles } from 'tss-react/mui';
import { useIntl } from 'ha/i18n';
import React from 'react';
import { useSelector } from 'react-redux';
import { getListingEntity } from 'ha/pages/Listing/selectors';
import { TypeValue } from 'ha/types/SearchFilters';
import { SmartTrack as Track } from 'ha/components/track/SmartTrack';
import { getCurrentLanguage } from 'ha/modules/AuthLogic/selectors';
import { MarketTypeTag } from '../MarketTypeTag';
import { MarketTypeVariant } from '../../types';

const useStyles = makeStyles()(theme => ({
  container: {
    padding: units.rem(1),
    marginTop: units.rem(1),
    marginBottom: units.rem(1),
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.grey[700],
  },
  tag: {
    marginLeft: units.rem(-1),
    marginTop: units.rem(-1),
    marginBottom: units.rem(1),
  },
  link: {
    display: 'block',
    marginTop: theme.spacing(3.5),
  },
}));

export interface Props {
  withTag?: boolean;
  variant?: MarketTypeVariant;
}

const MarketTypeBanner: React.FC<Props> = ({
  withTag = true,
  variant = MarketTypeVariant.STUDENT_HOUSING,
}) => {
  const { classes } = useStyles();
  const { T, urlResolver } = useIntl();
  const coLivingVariant = variant === MarketTypeVariant.CO_LIVING;

  const language = useSelector(getCurrentLanguage);
  const {
    country,
    countryCode,
    city,
    id,
    cityLocalized,
    price,
    type,
    countryLocalized,
    units: listingUnits,
  } = useSelector(getListingEntity);

  const path = React.useMemo(() => {
    return urlResolver.absolutizePath(
      urlResolver.getSearchUrl(
        cityLocalized || city,
        countryLocalized || country,
        {
          categories: TypeValue.STUDENT_HOUSING,
        },
      ),
      { skipAddLanguage: true },
    );
  }, [cityLocalized, city, countryLocalized, country, urlResolver]);

  return (
    <Grid item>
      <div className={classes.container}>
        {withTag && (
          <div className={classes.tag}>
            <MarketTypeTag variant={variant} />
          </div>
        )}
        <Typography.Paragraph>
          {coLivingVariant
            ? T('coliving.listing_page.banner_text')
            : T('student_accommodation.listing_page.banner_text')}
        </Typography.Paragraph>
        <Track
          name="Clicked Explore Student Residences"
          type="link"
          listingId={listingUnits?.[0]?.id}
          listingtype={type}
          unittypeid={id}
          country={country}
          listingcountry={countryCode}
          listingcity={city}
          PriceEur={price / 100}
          language={language}
        >
          <Link
            to={path}
            kind="underline"
            className={classes.link}
            data-test-locator="MarketTypeBanner/ExploreMoreLink"
          >
            {coLivingVariant
              ? T('coliving.explore_more', cityLocalized || city)
              : T('student_residence.explore_more', cityLocalized || city)}
          </Link>
        </Track>
      </div>
    </Grid>
  );
};

export { MarketTypeBanner };
