interface FeaturedCityInfo {
  isHidden?: boolean;
  hasAppartmentLink?: boolean;
  hasRoomLink?: boolean;
  urgencyMessageThreshold?: number;
}

const FeaturedCities: { [cityName: string]: FeaturedCityInfo } = {
  'Chapel Hill': { isHidden: true },
  Liège: { isHidden: true },
  Guadalajara: { isHidden: true },
  Tilburg: { isHidden: true },
  Bern: { isHidden: true },
  Lille: { isHidden: true },
  Turin: { isHidden: false },
  Salamanca: { isHidden: true },
  Murcia: { isHidden: true },
  Siena: { isHidden: true },
  Groningen: { isHidden: true },
  Hamburg: {
    isHidden: false,
    hasAppartmentLink: true,
    hasRoomLink: true,
    urgencyMessageThreshold: 20,
  },
  'Los Angeles': { hasAppartmentLink: true },
  Amsterdam: { hasAppartmentLink: true, hasRoomLink: true },
  Barcelona: {
    hasAppartmentLink: true,
    hasRoomLink: true,
    urgencyMessageThreshold: 11,
  },
  Dublin: {
    hasAppartmentLink: true,
    hasRoomLink: true,
    urgencyMessageThreshold: 28,
  },
  Rome: {
    hasAppartmentLink: true,
    hasRoomLink: true,
    urgencyMessageThreshold: 9,
  },
  Berkeley: { hasAppartmentLink: true },
  Valencia: { hasAppartmentLink: true },
  Berlin: { hasAppartmentLink: true, urgencyMessageThreshold: 21 },
  Prague: { hasAppartmentLink: true },
  Florence: { hasAppartmentLink: true, urgencyMessageThreshold: 13 },
  Madrid: { hasAppartmentLink: true, urgencyMessageThreshold: 8 },
  Lisbon: { hasAppartmentLink: true, urgencyMessageThreshold: 11 },
  Milan: { hasAppartmentLink: true, urgencyMessageThreshold: 8 },
  Columbus: { hasAppartmentLink: true },
  Vienna: { hasAppartmentLink: true, urgencyMessageThreshold: 14 },
  Córdoba: { hasAppartmentLink: true, hasRoomLink: true },
  Munich: { hasAppartmentLink: true, urgencyMessageThreshold: 24 },
  Shanghai: { hasAppartmentLink: true },
  Athens: { hasAppartmentLink: true },
  Sevilla: { hasAppartmentLink: true },
  Rotterdam: { hasAppartmentLink: true },
  Brussels: { hasAppartmentLink: true, urgencyMessageThreshold: 17 },
  Bordeaux: { hasAppartmentLink: true },
  Lyon: { hasAppartmentLink: true },
  Utrecht: { hasAppartmentLink: true },
  Ljubljana: { hasAppartmentLink: true, isHidden: true },
  'The Hague': { hasAppartmentLink: true },
  Bologna: { hasAppartmentLink: true },
  Budapest: { hasAppartmentLink: true },
  Padova: { hasAppartmentLink: true },
  Leipzig: { hasAppartmentLink: true, isHidden: true },
  Malaga: { isHidden: true },
  Cologne: { isHidden: true, urgencyMessageThreshold: 18 },
  'Frankfurt am Main': { isHidden: true, urgencyMessageThreshold: 17 },
};

export { FeaturedCities };
