import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { Fab, FabProps } from '@hbf/dsl/legacy';

const useStyles = makeStyles()(theme => ({
  white: {
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
    '@media (hover: none)': {
      '&:hover': {
        backgroundColor: theme.palette.common.white,
      },
    },
  },
  small: {
    fontSize: '1.25rem',
  },
  xs: {
    width: '30px',
    height: '30px',
    fontSize: '1rem',
    // by default Fab has minHeight: 36px
    minHeight: '30px',
  },
}));

// https://github.com/microsoft/TypeScript/issues/3402#issuecomment-385975990
// solution for overriding the prop type, Omit does not work for some reason
interface TempRoundIconButtonProps extends FabProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  color?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  size?: any;
}

export interface RoundIconButtonProps extends TempRoundIconButtonProps {
  color?: FabProps['color'] | 'white';
  size?: FabProps['size'] | 'xs';
}

export const RoundIconButton: React.FC<RoundIconButtonProps> = React.forwardRef(
  (
    { size = 'medium', color, className, ...rest }: RoundIconButtonProps,
    ref,
  ) => {
    const { classes, cx } = useStyles();

    return (
      <Fab
        size={size !== 'xs' ? size : undefined}
        color={color !== 'white' ? color : undefined}
        className={cx(
          className,
          color === 'white' && classes.white,
          size === 'small' && classes.small,
          size === 'xs' && classes.xs,
        )}
        {...rest}
        ref={ref}
      />
    );
  },
);

RoundIconButton.displayName = 'RoundIconButton';
