import { createReducer } from 'ha/myredux/createReducer';
import { Actions } from '../constants';
import { ListingState } from '../types';

type State = ListingState['translation'];

export const initialState: State = {
  sourceLanguage: '',
  translation: '',
  isLoading: false,
  isShown: false,
  error: false,
};

const translationReducer = createReducer(
  {
    [Actions.TRANSLATE_DESCRIPTION_START]: state => ({
      ...state,
      error: false,
      isLoading: true,
    }),
    [Actions.TRANSLATE_DESCRIPTION_DONE]: (state, payload) => ({
      ...state,
      translation: payload,
      error: false,
      isLoading: false,
      isShown: true,
    }),
    [Actions.TRANSLATE_DESCRIPTION_FAIL]: state => ({
      ...state,
      isLoading: false,
      error: true,
    }),
    [Actions.DETECT_DESCRIPTION_LANGUAGE_DONE]: (state, payload) => ({
      ...state,
      sourceLanguage: payload,
    }),
    [Actions.TOGGLE_DESCRIPTION_LANGUAGE]: state => ({
      ...state,
      isShown: !state.isShown,
    }),
  },
  initialState,
);

export const getDescriptionLanguage = (state: State) => state.sourceLanguage;
export const getIsTranslationLoading = (state: State) => state.isLoading;
export const getIsTranslationShown = (state: State) => state.isShown;
export const getTranslatedDescription = (state: State) => state.translation;
export { translationReducer };
