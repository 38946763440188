import { createReducer } from 'ha/myredux/createReducer';

import { Actions } from '../constants';
import { ListingState } from '../types';

type State = ListingState['rentalConditions'];

export const initialState: State = {
  isLoading: false,
};

const rentalConditionsReducer = createReducer(
  {
    [Actions.LOAD_RENTAL_CONDITIONS_START]: state => ({
      ...state,
      isLoading: true,
      data: undefined,
    }),
    [Actions.LOAD_RENTAL_CONDITIONS_DONE]: (state, payload) => ({
      ...state,
      isLoading: false,
      data: payload,
    }),
    [Actions.LOAD_RENTAL_CONDITIONS_FAIL]: state => ({
      ...state,
      isLoading: false,
    }),
    [Actions.CLEAR_RENTAL_CONDITIONS]: state => ({
      ...state,
      data: undefined,
      isLoading: false,
    }),
  },
  initialState,
);

export { rentalConditionsReducer };
