import { createSelector } from 'reselect';

import { getListingEntity } from '../reducer';

const getListingVisitsCount = createSelector(
  [getListingEntity],
  listing => listing.ldpVisits ?? 0,
);

export { getListingVisitsCount };
