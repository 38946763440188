import { createSelector } from 'reselect';
import { getLatitude, getLongitude } from '../reducer';

const getCoordinates = createSelector(
  [getLatitude, getLongitude],
  (lat, lng) => ({
    lat,
    lng,
  }),
);

export { getCoordinates };
